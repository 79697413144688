import React from 'react';
import { Route, Switch } from 'react-router-dom';
import InventoryOverview from './inventoryOverview';
import InventoryDetail from './inventoryDetail';


const InventoryRouter = props => {
    sessionStorage.setItem('page', 'Quick Inventory');
    return (
        <Switch>
            <Route exact path="/inventory" component={InventoryOverview} />
            <Route exact path="/inventory/:id" component={InventoryDetail} />
        </Switch>
    )
}

export default InventoryRouter;