import React from 'react';
import { BrowserRouter as Router, Redirect, Route } from 'react-router-dom';
import SideNav from './layout/sidenav';
import Banner from './layout/banner';
import OrderDetailRouter from './pages/orderDetail/orderDetailRouter';
import ControlsRouter from './pages/controls/controlsRouter';
import CertificateOfDestructionRouter from './pages/certificateOfDestruction/certificateOfDestructionRouter';
import IndatesOverview from './pages/indates/indatesOverview';
import CustomReport from './pages/customReport/customReport';
import InventoryRouter from './pages/inventory/inventoryRouter';
import CreditsRouter from './pages/credits/creditsRouter';
import CheckOverview from './pages/checks/checkOverview';
import LegacyRouter from './pages/legacy/legacyRouter';
import Login from './pages/login';
import SettingsRouter from './pages/settings/settingsRouter';

class App extends React.Component {
  constructor(props) { super(props); this.state = { arbitrary: true } }

  reRender = () => this.setState(p => ({ arbitrary: !p.arbitrary }))

  logout = e => {
    e.preventDefault();
    sessionStorage.clear();
    sessionStorage.setItem('logged_out', true);
    this.forceUpdate();
  }


  render = () => {
    if (!window.sessionStorage.getItem('auth_data'))
      return < Login renderParent={this.reRender} />
    else
      return (
        <div>
          <Router>
            {window.location.pathname === '/' && window.sessionStorage.getItem('auth_data') && <Redirect to="/orderdetail" />}
            <div style={{ position: 'relative' }}>
              <SideNav logout={this.logout} />
              <Banner />
              <Route path="/orderdetail" component={OrderDetailRouter} />
              <Route path="/controls" component={ControlsRouter} />
              <Route path="/destruction" component={CertificateOfDestructionRouter} />
              <Route exact path="/indates" component={IndatesOverview} />
              <Route exact path="/customreports" component={CustomReport} />
              <Route path="/inventory" component={InventoryRouter} />
              <Route path="/credits" component={CreditsRouter} />
              <Route path="/checks" component={CheckOverview} />
              <Route path="/legacy" component={LegacyRouter} />
              <Route path="/settings" component={SettingsRouter} />
            </div>
          </Router>
        </div>
      );
  }
}

export default App;