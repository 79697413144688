import React from 'react';
import M from 'materialize-css';
import { withRouter, Link } from 'react-router-dom';

class SideNav extends React.Component {
    constructor(props) { super(props); this.state = this.initState(); }

    initState = () => ({
        path: this.props.location.pathname.split('/')[1]
    })

    componentDidMount = () => {
        M.Sidenav.init(document.querySelectorAll('.sidenav'));
        M.Sidenav.getInstance(document.querySelector('#side-navigation')).open();
        this.unlisten = this.props.history.listen((location, action) => {
            this.setState({ path: location.pathname.split('/')[1] })
        })
    }

    componentWillUnmount = () => {
        document.querySelectorAll('.sidenav').forEach(el => {
            const sn = M.Sidenav.getInstance(el);
            if (sn)
                sn.destroy();
        })
        this.unlisten();
    }

    goHome = () => {
        this.props.history.push('/')
    }

    render = () => (
        <div style={{ margin: '0px', height: '0px', padding: '0px', }}>
            <ul id="side-navigation" className="sidenav sidenav-fixed">
                <li>
                    <div className="user-view" style={{ backgroundColor: '#eeeeeeaa' }}>
                        <p className="name" style={{ margin: '0px' }}>{`${sessionStorage.getItem('firstName')} ${sessionStorage.getItem('lastName')}`}<span className="grey-text text-darken-1"> - {sessionStorage.getItem('title')}</span></p>
                        <p className="email" style={{ padding: '0px', margin: '0px' }}>{sessionStorage.getItem('username')}</p>
                        <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', minHeight: '50px' }}>
                            <a className="email" href="/" onClick={this.props.logout} style={{ padding: '0px', margin: '0px', display: 'inline' }}>Sign Out</a>
                            <Link to="/settings"><i className="material-icons black-text">settings</i></Link>
                        </div>
                    </div>
                </li>
                {/* <li>
                    <Link to="/" className={this.state.path === '' ? 'red-text' : ''}>
                        <i className={`material-icons ${this.state.path === '' ? 'red-text' : ''}`}>pie_chart</i>
                    User Dashboard
                </Link>
                </li> */}
                <li>
                    <Link to="/orderdetail" className={this.state.path === 'orderdetail' ? 'red-text' : ''}>
                        <i className={`material-icons ${this.state.path === 'orderdetail' ? 'red-text' : ''}`}>assignment</i>
                    Regular Orders
                </Link>
                </li>
                <li>
                    <Link to="/controls" className={this.state.path === 'controls' ? 'red-text' : ''}>
                        <i className={`material-icons ${this.state.path === 'controls' ? 'red-text' : ''}`}>assignment_late</i>
                    Controls
                </Link>
                </li>
                <li>
                    <Link to="/credits" className={this.state.path === 'credits' ? 'red-text' : ''}>
                        <i className={`material-icons ${this.state.path === 'credits' ? 'red-text' : ''}`}>attach_money</i>
                    Credits
                </Link>
                </li>
                <li>
                    <Link to="/checks" className={this.state.path === 'checks' ? 'red-text' : ''}>
                        <i className={`material-icons ${this.state.path === 'checks' ? 'red-text' : ''}`}>payments</i>
                    Payments
                </Link>
                </li>
                <li>
                    <Link to="/destruction" className={this.state.path === 'destruction' ? 'red-text' : ''}>
                        <i className={`material-icons ${this.state.path === 'destruction' ? 'red-text' : ''}`}>local_fire_department</i>
                    Destruction
                </Link>
                </li>
                <li>
                    <Link to="/indates" className={this.state.path === 'indates' ? 'red-text' : ''}>
                        <i className={`material-icons ${this.state.path === 'indates' ? 'red-text' : ''}`}>snooze</i>
                    Indates
                </Link>
                </li>
                <li>
                    <Link to="/customreports" className={this.state.path === 'customreports' ? 'red-text' : ''}>
                        <i className={`material-icons ${this.state.path === 'customreports' ? 'red-text' : ''}`}>construction</i>
                    Custom Reports
                </Link>
                </li>
                <li>
                    <Link to="/inventory" className={this.state.path === 'inventory' ? 'red-text' : ''}>
                        <i className={`material-icons ${this.state.path === 'inventory' ? 'red-text' : ''}`}>medical_services</i>
                    Quick Inventory
                </Link>
                </li>
                <li>
                    <Link to="/legacy" className={this.state.path === 'legacy' ? 'red-text' : ''}>
                        <i className={`material-icons ${this.state.path === 'legacy' ? 'red-text' : ''}`}>history</i>
                    Legacy Orders
                </Link>
                </li>
            </ul>
            <a href="/" data-target="side-navigation" className="sidenav-trigger white-text hide-on-large-only"><i className="material-icons">menu</i></a>
        </div >
    )
}

export default withRouter(SideNav);