import React from 'react';
import { Route, Switch } from 'react-router-dom';
import OrderOverview from './orderOverview';
import OrderDetail from './orderDetail';


const OrderDetailRouter = props => {
    sessionStorage.setItem('page', 'Regular Orders');
    return (
        <Switch>
            <Route exact path="/orderdetail" component={OrderOverview} />
            <Route exact path="/orderdetail/:orderNumber" component={OrderDetail} />
        </Switch>
    )
}

export default OrderDetailRouter;