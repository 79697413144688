import React from 'react';
import Axios from 'axios';
import M from 'materialize-css';
import Chart from 'chart.js';


class CheckOverview extends React.Component {
    constructor(props) {
        super(props);
        this.state = this.initState();
        this.authData = JSON.parse(window.sessionStorage.getItem('auth_data'));
        this.pharmacies = JSON.parse(window.sessionStorage.getItem('pharmacies'));
        this.paymentPie = null;
    }

    initState = () => ({
        checks: null,
        selectedYear: '',
        yearArr: null,
        gettingChecks: false,
        pharmacyId: '0',
        msg: ''
    })

    componentDidMount = () => {
        sessionStorage.setItem('page', 'Payments');
        this.getChecks();
        M.FormSelect.init(document.querySelectorAll('select'));
        this.paymentPie = new Chart(document.querySelector('#payment-pie'), { type: 'pie', options: { legend: { display: false } } })
    }

    componentWillUnmount = () => {
        document.querySelectorAll('select').forEach(el => {
            const s = M.FormSelect.getInstance(el)
            if (s)
                s.destroy();
        });
    }

    getChecks = () => {
        this.setState({ gettingChecks: true }, () => {
            Axios.post('/api/v1/customerreports/checks', {
                ...this.authData,
                pharmacyId: this.state.pharmacyId
            })
                .then(result => {
                    this.sortChecks(result.data)
                    if (result.data && result.data[0])
                        return//this.getOrderTotals(0, result.data[0].memo)
                    else
                        this.setState({ msg: 'No Payments' })
                })
                .catch(err => {
                    if (err.response.status === 401) {
                        sessionStorage.clear();
                        sessionStorage.setItem('logged_out', true);
                        this.props.history.push('/');
                    }
                    else {
                        M.toast({ html: 'An unknown error occurred please try again.' })
                    }
                })
                .finally(() => this.setState({ gettingChecks: false }))
        })
    }

    sortChecks = arr => {
        if (!arr.length) { return this.setState({ checks: null }) }
        const checks = {}

        arr.forEach(check => {
            if (!checks[check.yearIssued])
                checks[check.yearIssued] = [];

            checks[check.yearIssued].push(check);
        })

        const yearArr = Object.keys(checks);

        const selectedYear = yearArr[yearArr.length - 1];

        this.setState({ checks, selectedYear, yearArr });
    }

    prev = e => {
        e.preventDefault();

        if (this.state.selectedYear === this.state.yearArr[0])
            return;

        const currentYear = this.state.yearArr.indexOf(this.state.selectedYear);
        const selectedYear = this.state.yearArr[currentYear - 1];
        this.setState({ selectedYear })
    }

    next = e => {
        e.preventDefault();

        if (this.state.selectedYear === this.state.yearArr[this.state.yearArr.length - 1])
            return;

        const currentYear = this.state.yearArr.indexOf(this.state.selectedYear);
        const selectedYear = this.state.yearArr[currentYear + 1];
        this.setState({ selectedYear })
    }

    yearClick = (e, yr) => { e.preventDefault(); this.setState({ selectedYear: yr }) }

    pharmacyChange = e => this.setState({ pharmacyId: e.target.value }, this.getChecks)

    getOrderTotals = (index, orderNumber) => {
        this.setState({ selectedIndex: index }, () => {
            Axios.post('/api/v1/customerreports/checks/doughnut', {
                ...this.authData,
                orderNumber
            })
                .then(result => this.deezDoughnuts(result.data[0]))
                .catch(err => console.log(err.response))
        })
    }

    deezDoughnuts = data => {
        const { totalPayments, totalReturnableValue } = data;
        this.paymentPie.data.labels = ['Paid', 'Unpaid'];
        this.paymentPie.data.datasets = [{
            backgroundColor: ['#00aa44', '#ccc'],
            data: [totalPayments, (parseFloat(totalReturnableValue) - parseFloat(totalPayments)).toFixed(2)]
        }]
        this.paymentPie.update();
    }

    render = () => (
        <div className="offset-for-sidenav grey lighten-5" style={{ paddingTop: '12px', minHeight: 'CALC(100vh - 128px' }}>
            <div className="row">
                <div className="col s12">
                    <div className="card">
                        <div className="card-content">
                            {this.pharmacies.length > 1 &&
                                <div className="row">
                                    <div className="input-field col s12 m6">
                                        <select onChange={this.pharmacyChange}>
                                            <option value={0}>All Pharmacies</option>
                                            {this.pharmacies.map((p, index) => <option key={`opt-${index}`} value={p.id}>{p.name}</option>)}
                                        </select>
                                        <label>Pharmacy</label>
                                    </div>
                                </div>
                            }
                            {this.state.gettingChecks ?
                                <div className="progress">
                                    <div className="indeterminate" />
                                </div>
                                :
                                <div>
                                    <div className="row">
                                        {this.state.checks &&
                                            <ul className="pagination center">
                                                <li className={this.state.selectedYear === this.state.yearArr[0] ? 'disabled' : ''}><a href="/" onClick={this.prev}><i className="material-icons">chevron_left</i></a></li>
                                                {this.state.checks && Object.keys(this.state.checks).map(yr => (
                                                    <li className={this.state.selectedYear === yr ? 'active blue' : ' waves-effect'} key={`opt-${yr}`}><a href="/" onClick={e => this.yearClick(e, yr)}>{yr}</a></li>
                                                ))}
                                                <li className={this.state.selectedYear === this.state.yearArr[this.state.yearArr.length - 1] ? 'disabled' : ''}><a href="/" onClick={this.next}><i className="material-icons">chevron_right</i></a></li>
                                            </ul>
                                        }
                                    </div>
                                    <div className="row">
                                        <table className="highlight">
                                            <thead>
                                                <tr>
                                                    <th>Date Issued</th>
                                                    <th>Check Number</th>
                                                    <th>Memo</th>
                                                    <th style={{ textAlign: 'right' }}>Amount</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {this.state.checks && this.state.checks[this.state.selectedYear].map((c, index) => (
                                                    <tr key={`td-${c.id}`} /*onClick={() => this.getOrderTotals(index, c.memo)}*/ className={this.state.selectedIndex === index ? 'grey lighten-3' : ''}>
                                                        <td style={{ padding: '6px' }}>{c.dateIssued}</td>
                                                        <td style={{ padding: '6px' }}>{c.checkNumber}</td>
                                                        <td style={{ padding: '6px' }}>{c.memo}</td>
                                                        <td style={{ padding: '6px', textAlign: 'right' }}>{parseFloat(c.dollarAmount).toFixed(2)}</td>
                                                    </tr>
                                                ))}
                                            </tbody>
                                        </table>
                                        <p>{this.state.msg}</p>
                                    </div>
                                </div>
                            }
                        </div>
                    </div>
                </div>
                <div className="col s12 m3 hide">
                    <div className="card">
                        <div className="card-content">
                            <h6 className="center">Total Payments By Order</h6>
                            <canvas id="payment-pie" height="220x" />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default CheckOverview;