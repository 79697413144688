import Axios from 'axios';
import React from 'react';
import moment from 'moment';
import M from 'materialize-css';
import { Link } from 'react-router-dom';
import Chart from 'chart.js';

class OrderOverview extends React.Component {
    constructor(props) {
        super(props);
        this.state = this.initState();
        this.authData = JSON.parse(sessionStorage.getItem('auth_data'));
        this.pharmacies = JSON.parse(sessionStorage.getItem('pharmacies'));
        this.barChart = null;
        this.totalDoughnut = null;
        this.classDoughnut = null;
        this.manufacturerChart = null;
        this.clearTimer = null;
        this.resizeWait = true;
        this.windowSize = window.innerWidth;
        this.oranges = ['#41248c', '#7b1fa2', '#9c27b0', '#ba68c8'];
    }

    initState = () => ({
        orders: null,
        orderHistory: {},
        pharmacyId: '0',
        gettingOrders: false,
        fryingDoughnuts: false,
        barchartOrders: null,
        barchartHeight: '',
        selectedOrderNumber: null,
        bargraphYears: null
    })

    componentDidMount = () => {
        this.getOrderDetail();
        this.initListeners();
        M.Modal.init(document.querySelectorAll('.modal'));
        M.FormSelect.init(document.querySelectorAll('select'));
        this.totalDoughnut = new Chart(document.querySelector('#totals-chart'), { type: 'doughnut', options: { cutoutPercentage: 80, legend: { display: false } } });
        this.classDoughnut = new Chart(document.querySelector('#class-chart'), { type: 'doughnut', options: { cutoutPercentage: 80, legend: { display: false } } });
        this.manufacturerChart = new Chart(document.querySelector('#manufacturers-chart'), { type: 'horizontalBar', options: { legend: { display: false } } })
        this.clearTimer = setInterval(() => this.resizeWait = false, 500);
    }



    componentWillUnmount = () => {
        this.removeListeners();
        clearTimeout(this.clearTimer);
        document.querySelectorAll('.modal').forEach(modal => {
            const m = M.Modal.getInstance(modal);
            if (m)
                m.destroy();
        });
        document.querySelectorAll('select').forEach(select => {
            const s = M.FormSelect.getInstance(select)
            if (s)
                s.destroy();
        });

        if (this.barChart) { this.barChart.destroy() };
        if (this.classDoughnut) { this.classDoughnut.destroy() };
        if (this.manufacturerChart) { this.manufacturerChart.destroy() };
        if (this.totalDoughnut) { this.totalDoughnut.destroy() };
    }

    initListeners = () => {
        window.addEventListener('resize', this.resizeListener)
    }

    removeListeners = () => {
        window.removeEventListener('resize', this.resizeListener);
    }

    resizeListener = () => {
        const resizeNotWarranted = Math.abs(this.windowSize - window.innerWidth) < 100;

        if (this.resizeWait || resizeNotWarranted)
            return;

        this.resizeWait = true;
        this.windowSize = window.innerWidth;
        this.barChart.destroy();
        this.initBarGraph(document.querySelector('#bg-years-select').value);
    }

    initBarGraph = (year) => {
        const thisYear = JSON.parse(JSON.stringify(this.state.orders)).filter(o => {
            return moment(o.orderDate, 'YYYY-MM-DD').year() === parseInt(year);
        });
        this.setState({ barchartOrders: thisYear }, () => {

            const ctx = document.querySelector('#controls-overview-bar-chart');
            if (this.barChart) { this.barChart.destroy() }
            this.barChart = new Chart(ctx, {
                type: 'bar',
                data: {
                    labels: thisYear.map(o => o.orderNumber),
                    datasets: [{
                        data: thisYear.map(o => o.totalEstimatedValue),
                        backgroundColor: thisYear.map(o => o.orderNumber === this.state.selectedOrderNumber ? '#24a064' : '#00e676'),
                        hoverBackgroundColor: '#24a064',
                        borderWidth: 1,
                        maxBarThickness: 60,
                        minBarLength: 12
                    }]
                },
                options: {
                    responsive: true,
                    maintainAspectRatio: true,
                    aspectRatio: window.innerWidth > 992 ? 6 : 2,
                    legend: { display: false },
                    onClick: e => this.barChartClick(e, thisYear),
                    onHover: function (e) {
                        const arr = this.getElementAtEvent(e);
                        if (arr.length) { e.target.style.cursor = 'pointer' }
                        else { e.target.style.cursor = 'default' }
                    },
                }
            })
        })
        this.getDoughnutDeets(this.state.selectedOrderNumber);
    }

    barChartClick = (e, thisYear) => {
        const arr = this.barChart.getElementAtEvent(e);
        if (arr.length) {
            this.setState({ selectedOrderNumber: thisYear[arr[0]._index].orderNumber }, () => {

                this.barChart.data.datasets[0].backgroundColor = this.state.barchartOrders.map(o => o.orderNumber === this.state.selectedOrderNumber ? '#24a064' : '#00e676');
                this.barChart.update();

                const orderNumber = thisYear[arr[0]._index].orderNumber;
                this.getDoughnutDeets(orderNumber);
            })
        }
    }

    getDoughnutDeets = orderNumber => {
        this.setState({ fryingDoughnuts: true }, () => {
            Axios.post('/api/v1/customerreports/controls/overview/chart/doughnut', {
                ...this.authData,
                orderNumber
            })
                .then(result => {
                    this.assortedDoughnuts(result.data);
                })
                .catch(err => {
                    if (err && err.response && err.response.status === 401) {
                        sessionStorage.clear();
                        sessionStorage.setItem('logged_out', true);
                        this.props.history.push('/');
                    }
                    else {
                        M.toast({ html: 'An unknown error occurred please try again.' })
                    }
                })
                .finally(() => this.setState({ fryingDoughnuts: false }))
        })
    }

    assortedDoughnuts = arr => {
        let totalReturnableValue = 0;
        let totalNonReturnableValue = 0;
        const classes = {};
        const manufacturers = {};

        arr.forEach(row => {
            if (row.nonReturnableReasonCodeId) {
                totalNonReturnableValue += parseFloat(row.estimatedReturnValue) || 0;

                if (classes[row.deaClass])
                    classes[row.deaClass] += parseFloat(row.estimatedReturnValue) || 0;
                else
                    classes[row.deaClass] = parseFloat(row.estimatedReturnValue) || 0;
            }
            else {
                totalReturnableValue += parseFloat(row.estimatedReturnValue) || 0;


                if (manufacturers[row.manufacturerName])
                    manufacturers[row.manufacturerName] += parseFloat(row.estimatedReturnValue) || 0;
                else
                    manufacturers[row.manufacturerName] = parseFloat(row.estimatedReturnValue) || 0;
            }
        })
        this.freshDoughnuts(totalReturnableValue, totalNonReturnableValue, classes, manufacturers);
    }

    freshDoughnuts = (totalReturnableValue, totalNonReturnableValue, classes, manufacturers) => {

        this.totalDoughnut.data.labels = ['Returnable', 'Non-Returnable'];
        this.totalDoughnut.data.datasets = [{
            backgroundColor: ['#f44336', '#ffebee'],
            data: [totalReturnableValue.toFixed(2), totalNonReturnableValue.toFixed(2)]
        }];
        this.totalDoughnut.update();

        this.classDoughnut.data.labels = Object.keys(classes).map(k => k);
        this.classDoughnut.data.datasets = [{
            label: null,
            backgroundColor: this.oranges,
            data: Object.values(classes).map(v => parseFloat(v).toFixed(2))
        }]
        this.classDoughnut.update();


        this.manufacturerChart.data.labels = Object.keys(manufacturers).map(k => k);
        this.manufacturerChart.data.datasets = [{
            backgroundColor: '#00897b',
            data: Object.values(manufacturers).map(v => parseFloat(v).toLocaleString('en-US', { minimumFractionDigits: 2 })),
            maxBarThickness: 20,
            minBarLength: 8
        }]
        this.manufacturerChart.update();

    }


    getOrderDetail = () => {
        this.setState({ gettingOrders: true }, () => {
            Axios.post('/api/v1/customerreports/orderswithcontrols', {
                ...this.authData,
                pharmacyId: this.state.pharmacyId
            })
                .then(result => {
                    const orders = JSON.parse(JSON.stringify(result.data));
                    orders.forEach(order => {
                        order.statuses.forEach(status => {
                            const { firstName, lastName } = status.employee;
                            const name = firstName ? `${firstName} ${lastName}` : '';
                            const date = status.timeStamp ? status.timeStamp : '';

                            switch (status.status) {
                                case 'Representative On-Site':
                                    order.repName = name;
                                    order.orderDate = date;
                                    break;
                                case 'Received':
                                    order.dateReceived = date;
                                    break;
                                case 'Sorted':
                                    order.sortedBy = name;
                                    order.dateSorted = date;
                                    break;
                                case 'Shipped to Manufacturer':
                                    order.debitMemoDate = date;
                                    break;
                                default:
                                    return;
                            }
                        })
                    })
                    orders.forEach(o => {
                        o.statuses.sort((a, b) => {
                            const timeA = new Date(a.timeStamp);
                            const timeB = new Date(b.timeStamp);
                            return timeA - timeB;
                        })
                    });
                    const yearsObj = {};
                    orders.forEach(o => yearsObj[moment(o.orderDate, 'YYYY-MM-DD').year()] = 1);
                    const bargraphYears = Object.keys(yearsObj).map(y => parseInt(y)).sort().reverse();

                    this.setState({
                        orders,
                        activeOrder: orders.length ? orders[0] : null,
                        selectedOrderNumber: orders.length ? orders[0].orderNumber : '',
                        bargraphYears
                    }, () => {
                        M.FormSelect.init(document.querySelector('#bg-years-select'))
                        this.initBarGraph(bargraphYears[0]);
                    });
                })
                .catch(err => {
                    if (err.response.status === 401) {
                        sessionStorage.clear();
                        sessionStorage.setItem('logged_out', true);
                        this.props.history.push('/');
                    }
                    else {
                        M.toast({ html: 'An unknown error occurred please try again.' })
                    }
                })
                .finally(() => this.setState({ gettingOrders: false }))
        })
    }


    showHistory = order => {
        this.setState({ orderHistory: order }, () => {
            M.Modal.getInstance(document.querySelector('#history-modal')).open();
        })
    }

    pharmacyChange = e => this.setState({ pharmacyId: e.target.value }, this.getOrderDetail);
    yearChange = e => this.setState({ bargraphYear: e.target.value }, () => this.initBarGraph(this.state.bargraphYear))

    handleRowClick = orderNumber => {
        this.setState({ selectedOrderNumber: orderNumber }, () => {
            this.barChart.data.datasets[0].backgroundColor = this.state.barchartOrders.map(o => o.orderNumber === this.state.selectedOrderNumber ? '#24a064' : '#00e676');
            this.barChart.update();

            this.getDoughnutDeets(orderNumber);
        })
    }

    render = () => (
        <div className="offset-for-sidenav grey lighten-5" style={{ paddingTop: '12px', minHeight: 'CALC(100vh - 128px)' }}>
            <div className="row">
                <div className="col s12">
                    <div className="card">
                        <div className="card-content">
                            <div className="row">
                                <div className="input-field col s6 m2 right">
                                    <select id="bg-years-select" onChange={this.yearChange}>
                                        {this.state.bargraphYears && this.state.bargraphYears.map(y => <option key={`year-${y}`} value={y}>{y}</option>)}
                                    </select>
                                </div>
                            </div>
                            <h5 className="center">Estimated Value of Controls by Order</h5>
                            <canvas id="controls-overview-bar-chart" height={this.state.barchartHeight} />
                        </div>
                    </div>
                </div>
            </div>
            <div className="row">
                <div className="col m12 l9">
                    <div className="card">
                        <div className="card-content">
                            {this.pharmacies.length > 1 &&
                                <div className="row">
                                    <div className="input-field col s12 m6">
                                        <select onChange={this.pharmacyChange}>
                                            <option value={0}>All Pharmacies</option>
                                            {this.pharmacies.map((p, index) => <option key={`opt-${index}`} value={p.id}>{p.name}</option>)}
                                        </select>
                                        <label>Pharmacy</label>
                                    </div>
                                </div>
                            }
                            {!this.state.gettingOrders ? (
                                <table className="highlight">
                                    <thead>
                                        <tr>
                                            <th>Order Number</th>
                                            <th>Order Date</th>
                                            <th>Status</th>
                                            <th className="hide-on-small-only">Rep Name</th>
                                            <th className="hide-on-small-only">Date Recieved</th>
                                            <th>History</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {this.state.orders && this.state.orders.map(o => (
                                            <tr key={`tr-${o.id}`} onClick={() => this.handleRowClick(o.orderNumber)} className={o.orderNumber === this.state.selectedOrderNumber ? 'grey lighten-2' : ''}>
                                                <td style={{ padding: '6px' }} ><Link to={`/controls/${o.orderNumber}`}>{o.orderNumber}</Link></td>
                                                <td style={{ padding: '6px' }}>{o.orderDate ? moment(o.orderDate, 'YYYY-MM-DD').format('MM/DD/YY') : ''}</td>
                                                <td style={{ padding: '6px' }}>{o.statuses[o.statuses.length - 1].status}</td>
                                                <td className="hide-on-small-only" style={{ padding: '6px' }}>{o.repName}</td>
                                                <td className="hide-on-small-only" style={{ padding: '6px' }}>{o.dateReceived ? moment(o.dateReceived, 'YYYY-MM-DD').format('MM/DD/YY') : ''}</td>
                                                <td style={{ padding: '6px' }}><i style={{ cursor: 'pointer' }} className="material-icons" onClick={() => this.showHistory(JSON.parse(JSON.stringify(o)))}>history</i></td>
                                            </tr>
                                        ))}
                                    </tbody>
                                </table>
                            )
                                :
                                <div className="progress">
                                    <div className="indeterminate" />
                                </div>
                            }
                        </div>
                    </div>
                </div>
                <div className="col m12 l3">
                    <div className="row">
                        <div className="col s12">
                            <div className="card">
                                <div className="card-content">
                                    <h6>Estimated Value by Return Status</h6>
                                    <canvas id="totals-chart" height="174px" />
                                </div>
                            </div>
                        </div>
                        <div className="col s12">
                            <div className="card">
                                <div className="card-content">
                                    <h6 className="center">Volume by DEA Class</h6>
                                    <canvas id="class-chart" height="174px" />
                                </div>
                            </div>
                        </div>
                        <div className="col s12">
                            <div className="card">
                                <div className="card-content">
                                    <h6 className="center">Estimated Value By Manufacturer</h6>
                                    <canvas id="manufacturers-chart" height="480px" />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div id="history-modal" className="modal">
                <div className="modal-content">
                    <h5>Order #{this.state.orderHistory.orderNumber}</h5>
                    <table>
                        <thead>
                            <tr>
                                <th>Status</th>
                                <th>Time Stamp</th>
                            </tr>
                        </thead>
                        <tbody>
                            {this.state.orderHistory.statuses && this.state.orderHistory.statuses.map(status => (
                                <tr key={`status-tr-${status.id}`}>
                                    <td>{status.status}</td>
                                    <td>{status.timeStamp ? moment(status.timeStamp, 'YYYY-MM-DD').format('MM/DD/YY') : ''}</td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    )
}

export default OrderOverview;