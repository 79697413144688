import React from 'react';
import OrderSearch from './orderSearch';
import MedSearch from './medSearch';

class CustomReport extends React.Component {
    constructor(props) { super(props); this.state = this.initState(); }

    initState = () => ({
        searchByMed: false
    })

    onChange = (name, value) => this.setState({ [name]: value })

    componentDidMount = () => {
        sessionStorage.setItem('page', 'Custom Reports');
    }

    render = () => (
        <div className="offset-for-sidenav grey lighten-5" style={{ paddingTop: '15px', minHeight: 'CALC(100vh - 128px)' }}>
            <div className="row">
                <div className="input-field">
                    <div className="switch">
                        <label>
                            Orders
                    <input name="searchByMed" type="checkbox" onChange={e => this.onChange(e.target.name, e.target.checked)} />
                            <span className="lever red red-text" />
                    Meds
                </label>
                    </div>
                </div>
            </div>
            <div className="row">
                <div className="col s12">
                    <div className="card">
                        <div className="card-content" style={{ paddingBottom: '80px' }}>
                            {this.state.searchByMed ? <MedSearch /> : <OrderSearch />}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default CustomReport;