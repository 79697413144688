import Axios from 'axios';
import React from 'react';
import { withRouter } from 'react-router-dom';
import M from 'materialize-css';
import Chart from 'chart.js';
import moment from 'moment';

class CreditOverview extends React.Component {
    constructor(props) {
        super(props);
        this.state = this.initState();
        this.authData = JSON.parse(window.sessionStorage.getItem('auth_data'));
        this.pharmacies = JSON.parse(window.sessionStorage.getItem('pharmacies'));
        this.bargraph = null;
        this.clearTimer = null;
        this.resizeWait = true;
        this.windowSize = window.innerWidth;
    }

    initState = () => ({
        orders: null,
        gettingOrders: false,
        pharmacyId: '0',
        bargraphYears: null,
        bargraphHeight: ''
    })

    componentDidMount = () => {
        this.getOrders();
        this.initListeners();
        M.FormSelect.init(document.querySelectorAll('select'));
        this.bargraph = new Chart(document.querySelector('#credits-overview-bar-graph'), { type: 'bar', options: { legend: { display: false }, scales: { xAxes: [{ stacked: true }] } } })
        this.clearTimer = setInterval(() => this.resizeWait = false, 500);
    }

    componentWillUnmount = () => {
        this.removeListeners();
        document.querySelectorAll('select').forEach(el => {
            const s = M.FormSelect.getInstance(el);
            if (s)
                s.destroy();
        });
    }

    initListeners = () => {
        window.addEventListener('resize', this.resizeListener);
    }

    removeListeners = () => {
        window.removeEventListener('resize', this.resizeListener);
    }

    resizeListener = e => {
        const resizeNotWarranted = Math.abs(this.windowSize - window.innerWidth) < 100;

        if (this.resizeWait || resizeNotWarranted)
            return;

        this.resizeWait = true;
        this.windowSize = window.innerWidth;
        this.bargraph.destroy();
        this.initBarGraph(document.querySelector('#bg-years-select').value);
    }

    getOrders = () => {
        this.setState({ gettingOrders: true }, () => {
            Axios.post('/api/v1/customerreports/credits/overview', {
                ...this.authData,
                pharmacyId: this.state.pharmacyId
            })
                .then(result =>
                    this.setState({ orders: result.data }, () => {
                        const yearsObj = {};
                        result.data.forEach(o => yearsObj[moment(o.serviceDate, 'MM/DD/YY').year()] = 1);
                        const bargraphYears = Object.keys(yearsObj).map(y => parseInt(y)).sort().reverse();
                        this.setState({ bargraphYears }, () => M.FormSelect.init(document.querySelectorAll('#bg-years-select')))
                        this.initBarGraph(bargraphYears[0]);
                    }))
                .catch(err => {
                    if (err.response.status === 401) {
                        sessionStorage.clear();
                        sessionStorage.setItem('logged_out', true);
                        this.props.history.push('/');
                    }
                    else {
                        M.toast({ html: 'An unknown error occurred please try again.' })
                    }
                })
                .finally(() => this.setState({ gettingOrders: false }))
        })
    }

    initBarGraph = (year) => {
        const arr = this.state.orders.filter(o => moment(o.serviceDate, 'MM/DD/YY').year() === parseInt(year));
        const ctx = document.querySelector('#credits-overview-bar-graph');
        if (this.bargraph) { this.bargraph.destroy() }
        this.bargraph = new Chart(ctx, {
            type: 'bar',
            data: {
                labels: arr.map(o => o.orderNumber),
                datasets: [
                    {
                        data: arr.map(o => parseFloat(o.amountCredited || 0).toFixed(2)),
                        backgroundColor: '#24a064',
                        borderColor: '#000',
                        borderWidth: 1,
                        maxBarThickness: 60,
                        minBarLength: 12
                    },
                    {
                        data: arr.map(o => (parseFloat(o.estimatedValue) - parseFloat(o.amountCredited || 0)).toFixed(2)),
                        backgroundColor: '#24a06422',
                        borderColor: '#000',
                        borderWidth: 1,
                        maxBarThickness: 60,
                        minBarLength: 12
                    }
                ]
            },
            options: {
                responsive: true,
                maintainAspectRatio: true,
                aspectRatio: window.innerWidth > 992 ? 6 : 2,
                legend: { display: false },
                scales: {
                    xAxes: [{
                        stacked: true
                    }],
                    yAxes: [{
                        stacked: true
                    }]
                }
            }
        })
    }

    goToDetails = (e, id, orderNumber) => {
        e.preventDefault();
        this.props.history.push(`/credits/${orderNumber}`, { orderHeaderId: id });
    }

    pharmacyChange = e => this.setState({ pharmacyId: e.target.value }, this.getOrders)
    bargraphYearChange = e => this.setState({ bargraphYear: e.target.value }, () => this.initBarGraph(this.state.bargraphYear));

    render = () => (
        <div className="offset-for-sidenav grey lighten-5" style={{paddingTop: '12px', minHeight: 'CALC(100vh - 128px)'}}>
            <div className="row">
                <div className="col s12">
                    <div className="card">
                        <div className="card-content">
                            <div className="row">
                                <div className="input-field col s4 m2 l2 right">
                                    <select id="bg-years-select" onChange={this.bargraphYearChange}>
                                        {this.state.bargraphYears && this.state.bargraphYears.map(y => <option key={`year-${y}`} value={y}>{y}</option>)}
                                    </select>
                                </div>
                            </div>
                            <h5 className="center">Credits Received By Order</h5>
                            <canvas id="credits-overview-bar-graph" height={this.state.bargraphHeight} />
                        </div>
                    </div>
                </div>
            </div>
            <div className="row">
                <div className="col s12">
                    <div className="card">
                        <div className="card-content">
                            {this.pharmacies.length > 1 &&
                                <div className="row">
                                    <div className="input-field col s12 m6">
                                        <select onChange={this.pharmacyChange}>
                                            <option value={0}>All Pharmacies</option>
                                            {this.pharmacies.map((p, index) => <option key={`opt-${index}`} value={p.id}>{p.name}</option>)}
                                        </select>
                                        <label>Pharmacy</label>
                                    </div>
                                </div>
                            }
                            <div className="row">
                                {this.state.gettingOrders ?
                                    <div className="progress">
                                        <div className="indeterminate" />
                                    </div>
                                    :
                                    <table>
                                        <thead>
                                            <tr>
                                                <th>Order Number</th>
                                                <th>Service Date</th>
                                                <th style={{ textAlign: 'right' }}>Estimated Value</th>
                                                <th style={{ textAlign: 'right' }}>Amount Received</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {this.state.orders && this.state.orders.map(row => (
                                                <tr key={`tr-${row.orderNumber}`}>
                                                    <td style={{ padding: '6px' }}><a href="/" onClick={e => this.goToDetails(e, row.id, row.orderNumber)}>{row.orderNumber}</a></td>
                                                    <td style={{ padding: '6px' }}>{row.serviceDate}</td>
                                                    <td style={{ padding: '6px', textAlign: 'right' }}>{parseFloat(row.estimatedValue || 0).toFixed(2)}</td>
                                                    <td style={{ padding: '6px', textAlign: 'right' }}>{parseFloat(row.amountCredited || 0).toFixed(2)}</td>
                                                </tr>
                                            ))}
                                        </tbody>
                                    </table>
                                }
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default withRouter(CreditOverview);