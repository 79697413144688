import React from 'react';
import M from 'materialize-css';
import moment from 'moment';
import Axios from 'axios';
import { Link } from 'react-router-dom';

class OrderSearch extends React.Component {
    constructor(props) {
        super(props);
        this.state = this.initState();
        this.authData = JSON.parse(sessionStorage.getItem('auth_data'));
        this.pharmacies = JSON.parse(sessionStorage.getItem('pharmacies'));
        this.isVa = null;
    }

    initState = () => ({
        searchOrderNumber: '',
        searchStartDate: '',
        searchEndDate: '',
        searchStatus: 'Any Status',
        statuses: [
            'Any Status',
            'Representative On-Site',
            'Shipped to Outdate Rx',
            'Received',
            'Sort in Progress',
            'Sorted',
            'Debit Memo Created',
            'Shipped to Manufacturer'
        ],
        headers: [
            'Order Number',
            'Order Date',
            'Status',
            'Rep Name',
            'Date Received',
            'History',
            'Download'
        ],
        orders: null,
        isAsc: true,
        header: 'Order Number',
        orderHistory: {},
        pharmacyId: '0',
        gettingOrders: false
    })

    componentDidMount = () => {
        M.Datepicker.init(document.querySelectorAll('.datepicker'), {
            showClearBtn: true,
            format: 'mmm dd, yyyy'
        });
        M.FormSelect.init(document.querySelectorAll('select'));
        M.Modal.init(document.querySelectorAll('.modal'));
    }

    componentWillUnmount = () => {
        document.querySelectorAll('.datepicker').forEach(el => {
            const dp = M.Datepicker.getInstance(el);
            if (dp)
                dp.destroy();
        });
        document.querySelectorAll('select').forEach(el => {
            const s = M.FormSelect.getInstance(el);
            if (s)
                s.destroy();
        });
        document.querySelectorAll('.modal').forEach(el => {
            const m = M.Modal.getInstance(el);
            if (m)
                m.destroy();
        });
    }

    onChange = e => this.setState({ [e.target.name]: e.target.value })
    selectChange = e => this.setState({ searchStatus: this.state.statuses[e.target.value] })

    handleDates = () => {
        const values = [];
        document.querySelectorAll('.datepicker').forEach(el => {
            const dp = M.Datepicker.getInstance(el);
            values.push(moment(dp.date, 'ddd MMM DD YYYY').format('YYYY-MM-DD'));
        })

        if (values[0] === 'Invalid date')
            values[0] = '2000-01-01';
        if (values[1] === 'Invalid date')
            values[1] = '2030-12-31';

        if (moment(values[0]).isAfter(moment(values[1])))
            values.push('Start Date must be before End Date');

        return values;
    }

    search = e => {
        if (e) { e.preventDefault() }
        this.setState({ gettingOrders: true }, () => {
            const [startDate, endDate, err] = this.handleDates();
            if (err)
                return this.setState({ dateErr: err, gettingOrders: false })

            Axios.post('/api/v1/customerreports/custom/by/order', {
                ...this.authData,
                pharmacyId: this.state.pharmacyId,
                orderNumber: this.state.searchOrderNumber,
                startDate,
                endDate,
                status: this.state.searchStatus,
                isAsc: this.state.isAsc,
                header: this.state.header
            })
                .then(result => this.setState({ orders: result.data }))
                .catch(err => {
                    if (err.response.status === 401) {
                        sessionStorage.clear();
                        sessionStorage.setItem('logged_out', true);
                        this.props.history.push('/');
                    }
                    else {
                        M.toast({ html: 'An unknown error occurred please try again.' })
                    }
                })
                .finally(() => this.setState({ gettingOrders: false, dateErr: '' }))
        })
    }

    setHeader = header => {
        if (header === 'History' || !this.state.orders)
            return;

        if (header === this.state.header)
            this.setState(p => ({ isAsc: !p.isAsc }), this.search)
        else
            this.setState({ header, isAsc: true }, this.search)
    }

    showHistory = order => {
        this.setState({ orderHistory: order }, () => {
            M.Modal.getInstance(document.querySelector('#history-modal')).open();
        })
    }

    pharmacyChange = e => this.setState({ pharmacyId: e.target.value })

    getIsVa = (orderNumber) => new Promise((resolve, reject) => {
        Axios.post('/api/v1/customerreports/isvabyordernumber', { ...this.authData, orderNumber })
            .then(result => this.setState({ isVa: result.data.isVa }, () => {
                resolve(result.data.isVa)
            }))
            .catch(err => {
                if (err.response.status === 401) {
                    sessionStorage.clear();
                    sessionStorage.setItem('logged_out', true);
                    this.props.history.push('/');
                }
                else {
                    M.toast({ html: 'An unknown error occurred please try again.' })
                }
                reject(false);
            })
    })

    download = async (e, orderNumber, type) => {

        e.preventDefault();

        if (this.isVa === null)
            this.isVa = await this.getIsVa(orderNumber);

        Axios.post(`/api/v1/customerreports/detail/${type}`, {
            ...this.authData,
            orderNumber,
            isVa: this.isVa
        }, { responseType: 'blob' })
            .then(result => {
                const url = window.URL.createObjectURL(new Blob([result.data]));
                const link = document.createElement('a');
                link.hidden = true;
                link.href = url;
                link.download = `${orderNumber}.${type}`;
                link.click();
            })
            .catch(err => {
                console.log(err);
                if (err.response.status === 401) {
                    sessionStorage.clear();
                    sessionStorage.setItem('logged_out', true);
                    this.props.history.push('/');
                }
                else {
                    M.toast({ html: 'An unknown error occurred please try again.' })
                }
            })
    }

    render = () => (
        <div className="container-fluid">
            <div className="row">
                <div className="row col s12 m10">
                    <div className="input-field col s12 m3">
                        <input id="_searchOrderNumber" name="searchOrderNumber" type="text" onChange={this.onChange} value={this.state.searchOrderNumber} />
                        <label htmlFor="_searchOrderNumber">Order Number</label>
                    </div>
                    <div className="input-field col s12 m3">
                        <input id="start-date" name="searchStartDate" type="text" className="datepicker" />
                        <label htmlFor="start-date">Start Date</label>
                        <span className="helper-text red-text">{this.state.dateErr}</span>
                    </div>
                    <div className="input-field col s12 m3">
                        <input id="_searchEndDate" name="searchEndDate" type="text" className="datepicker" />
                        <label htmlFor="_searchEndDate">End Date</label>
                    </div>
                    <div className="input-field col s12 m3">
                        <select onChange={this.selectChange}>
                            {this.state.statuses.map((status, index) => (<option key={`opt-${index}`} value={index}>{status}</option>))}
                        </select>
                        <label>Status</label>
                    </div>
                </div>
                <div className="row col s12 m2">
                    <div className="input-field">
                        {this.state.gettingOrders ?
                            <div className="preloader-wrapper small active">
                                <div className="spinner-layer spinner-blue-only">
                                    <div className="circle-clipper left">
                                        <div className="circle"></div>
                                    </div><div className="gap-patch">
                                        <div className="circle"></div>
                                    </div><div className="circle-clipper right">
                                        <div className="circle"></div>
                                    </div>
                                </div>
                            </div>
                            :
                            <a className="waves-effect btn-small blue white-text" href="/" onClick={this.search}>Search</a>
                        }
                    </div>
                </div>
            </div>
            {this.pharmacies.length > 1 &&
                <div className="row">
                    <div className="input-field col s12 m6">
                        <select onChange={this.pharmacyChange}>
                            <option value={0}>All Pharmacies</option>
                            {this.pharmacies.map((p, index) => <option key={`opt-${index}`} value={p.id}>{p.name}</option>)}
                        </select>
                        <label>Pharmacy</label>
                    </div>
                </div>
            }
            <div className="row">
                {this.state.orders &&
                    <h6 className="grey-text">{this.state.orders.length} Results</h6>
                }
            </div>
            <div className="row">
                <table>
                    <thead>
                        <tr>
                            {this.state.headers.map(header => (
                                <th
                                    key={`th-${header}`}
                                    onClick={() => this.setHeader(header)}
                                    style={{ cursor: 'pointer' }}
                                >
                                    {header}
                                    {this.state.header === header ? (
                                        <i className="material-icons">{this.state.isAsc ? 'arrow_drop_up' : 'arrow_drop_down'}</i>
                                    ) : null}
                                </th>
                            ))}
                        </tr>
                    </thead>
                    <tbody>
                        {this.state.orders && this.state.orders.map(o => (
                            <tr key={`td-${o.id}`}>
                                <td style={{ padding: '6px' }}><Link to={`/orderdetail/${o.orderNumber}`}>{o.orderNumber}</Link></td>
                                <td style={{ padding: '6px' }}>{o.orderDate}</td>
                                <td style={{ padding: '6px' }}>{o.status}</td>
                                <td style={{ padding: '6px' }}>{o.repName}</td>
                                <td style={{ padding: '6px' }}>{o.dateReceived}</td>
                                <td style={{ padding: '6px' }}><i style={{ cursor: 'pointer' }} className="material-icons" onClick={() => this.showHistory(JSON.parse(JSON.stringify(o)))}>history</i></td>
                                <td style={{ padding: '6px' }}><a href="/" onClick={e => this.download(e, o.orderNumber, 'pdf')}>PDF</a>/<a href="/" onClick={e => this.download(e, o.orderNumber, 'csv')}>CSV</a></td>
                            </tr>
                        ))}
                    </tbody>
                </table>
            </div>
            <div id="history-modal" className="modal">
                <div className="modal-content">
                    <h5>Order #{this.state.orderHistory.orderNumber}</h5>
                    <table>
                        <thead>
                            <tr>
                                <th>Status</th>
                                <th>Time Stamp</th>
                            </tr>
                        </thead>
                        <tbody>
                            {this.state.orderHistory.statuses && this.state.orderHistory.statuses.map(status => (
                                <tr key={`status-tr-${status.id}`}>
                                    <td>{status.status}</td>
                                    <td>{status.timeStamp ? status.timeStamp : ''}</td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    )
}

export default OrderSearch;
