import React from 'react';
import { withRouter } from 'react-router-dom';

class Banner extends React.Component {
    constructor(props) {
        super(props);
        this.state = { page: '' }
        this.unlisten = null;
        this.isLogMet = this.getIsLogmet();
        this.isPharmaVerse = this.getIsPharmaverse();
    };

    getIsLogmet = () => {
        const [domain] = window.location.toString().split('.com');

        if (!domain)
            return false;
        else if (domain.search('logmet') !== -1)
            return true
        else
            return false
    }

    getIsPharmaverse = () => {
        const [domain] = window.location.toString().split('.com');

        if (!domain)
            return false
        else if (domain.search('pharmaverse') !== -1)
            return true
        else
            return false
    }

    componentDidMount = () => {
        setTimeout(() => {
            this.setState({ page: sessionStorage.getItem('page') })
        }, 50)

        this.unlisten = this.props.history.listen(() => {
            setTimeout(() => {
                this.setState({ page: sessionStorage.getItem('page') })
            }, 20);
        })
    }

    componentWillUnmount = () => {
        this.unlisten();
    }


    render = () => (
        <div id="hulk-banner" className="red" style={{ height: '128px', maxWidth: '100%', zIndex: -1 }}>
            <span className="center white-text">{this.state.page}</span>
            <div>
                {this.isLogMet ?
                    <div>
                        <a href="/orderdetail">
                            <img className="responsive-img right hide-on-med-and-down" src="/img/logmet_logo_transparent.png" alt="LogMet Logo" style={{ maxHeight: '90px', position: 'relative', right: '340px', bottom: '40px' }} />
                        </a>
                        <span className="right hide-on-med-and-down" style={{ position: 'relative', top: '28px' }}>Powered By: <span className="red-text" style={{ fontSize: '1.1em', fontWeight: '600' }}>Outdate Rx</span></span>

                    </div>
                    : this.isPharmaVerse ?
                        <div>
                            <a href="/orderdetail">
                                <img className="responsive-img right hide-on-med-and-down" src="/img/pharmaverse_logo.png" alt="PharmaVerse Logo" style={{ maxHeight: '90px', width: 'auto', position: 'relative', right: '300px', bottom: '40px' }} />
                            </a>
                            <span className="right hide-on-med-and-down" style={{ position: 'relative', top: '20px', left: '200px' }}>Powered By: <span className="red-text" style={{ fontSize: '1.1em', fontWeight: '600' }}>Outdate Rx</span></span>
                        </div>
                        :
                        <a href="/orderdetail">
                            <img className="responsive-img right hide-on-med-and-down" src="/img/orx_pill_no_background.png" alt="Outdate Rx Logo" style={{ maxHeight: '90px', position: 'relative', bottom: '40px', right: '24px' }} />
                        </a>
                }
            </div>
        </div>
    )
}

export default withRouter(Banner);