import React from 'react';
import Axios from 'axios';
import M from 'materialize-css';
import moment from 'moment';

class LegacyRegularDetail extends React.Component {
    constructor(props) {
        super(props);
        this.authData = JSON.parse(sessionStorage.getItem('auth_data'));
        this.orderNumber = this.props.match.params.orderNumber;
        this.order = this.props.location.state.order;
        this.state = this.initState();
        console.log(this.order);
    }

    initState = () => ({
        loading: false,
        loadingPrintable: false,
        fullDetailList: null,
        returnableList: null,
        nonReturnableList: null,
        reportType: 'all',
        pdf: false,
        url: null,
        type: ''
    })

    componentDidMount = () => {
        sessionStorage.setItem('page', `Legacy Order - ${this.orderNumber}`);
        this.getDetails();
    }

    getDetails = () => {
        this.setState({ loading: true }, () => {
            Axios.post('/api/v1/customerreports/legacy/detail', {
                ...this.authData,
                orderNumber: this.orderNumber
            })
                .then(result => {
                    const returnableList = [];
                    const nonReturnableList = [];
                    result.data.forEach(od => od.isReturnable ? nonReturnableList.push(od) : returnableList.push(od));

                    this.setState({ fullDetailList: result.data, nonReturnableList, returnableList })
                })
                .catch(err => {
                    if (err.response.status === 401) {
                        sessionStorage.clear();
                        sessionStorage.setItem('logged_out', true);
                        this.props.history.push('/');
                    }
                    else {
                        M.toast({ html: 'An unknown error occurred please try again.' })
                    }
                })
                .finally(() => this.setState({ loading: false }, () => {
                    M.FormSelect.init(document.querySelectorAll('select'))
                }))
        })
    }

    getAvailableReports = () => ([
        { name: 'All Meds', value: 'all' },
        { name: 'Regular Meds', value: 'regular' },
        { name: 'Controls', value: 'controls' },
        { name: 'Destruction', value: 'destruction' },
        { name: 'Indates', value: 'indates' }
    ])

    onChange = e => this.setState({ [e.target.id]: e.target.value, url: null }, () => console.log(this.state))

    getReport = e => {
        e.preventDefault();

        this.setState({ loadingPrintable: true, url: null, type: null }, () => {
            Axios.post(`/api/v1/customerreports/legacy/report/${this.state.reportType}`, {
                ...this.authData,
                docType: this.state.pdf ? 'csv' : 'pdf',
                orderNumber: this.orderNumber
            }, { responseType: 'blob' })
                .then(result => {
                    let url;
                    if (this.state.pdf)
                        url = window.URL.createObjectURL(new Blob([result.data]));
                    else
                        url = window.URL.createObjectURL(new Blob([result.data], { type: 'text/csv;charset=utf-8' }));

                    const type = this.state.pdf ? 'csv' : 'pdf';
                    this.setState({ url, type })
                })
                .catch(err => {
                    if (err.response.status === 401) {
                        sessionStorage.clear();
                        sessionStorage.setItem('logged_out', true);
                        this.props.history.push('/');
                    }
                    else {
                        M.toast({ html: 'An unknown error occurred, please try again.' })
                    }
                })
                .finally(() => this.setState({ loadingPrintable: false }))
        })
    }

    render = () => (
        <div className="offset-for-sidenav grey lighten-5">
            <div className="row">
                <h6>{this.order.name} - {moment(this.order.orderDate).format('MMM DD, YYYY')}</h6>
            </div>
            {this.state.loading ?
                <div className="progress">
                    <div className="indeterminate" />
                </div>
                :
                <div>
                    <div className="row">
                        <div className="col s12">
                            <div className="card">
                                <div className="card-content">
                                    <form>
                                        <div className="row">
                                            <div className="input-field col s12 m4">
                                                <select id="reportType" onChange={this.onChange} value={this.state.reportType} >
                                                    {this.getAvailableReports().map(r => (
                                                        <option key={`rn-${r.value}`} value={r.value}>{r.name}</option>
                                                    ))}
                                                </select>
                                                <label htmlFor="reportType">Report To Print</label>
                                            </div>
                                            <div className="input-field col s12 m2">
                                                <div className="switch">
                                                    <label>
                                                        PDF
                                                    <input type="checkbox" checked={this.state.pdf} onChange={() => this.setState(p => ({ pdf: !p.pdf, url: null }))} />
                                                        <span className="lever blue" />
                                                    CSV
                                                </label>
                                                </div>
                                            </div>
                                            <div className="input-field col s12 m4 right row" >
                                                <button type="submit" className="btn-small col s5 blue white-text waves-effect" onClick={this.getReport}>Get Report</button>
                                                {this.state.loadingPrintable ?
                                                    <div className="col s5 offset-s2">
                                                        <div className="preloader-wrapper small active">
                                                            <div className="spinner-layer spinner-blue-only">
                                                                <div className="circle-clipper left">
                                                                    <div className="circle"></div>
                                                                </div><div className="gap-patch">
                                                                    <div className="circle"></div>
                                                                </div><div className="circle-clipper right">
                                                                    <div className="circle"></div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    :
                                                    <div></div>
                                                }
                                                {this.state.url && !this.state.loadingPrintable && <a download={`${this.state.reportType}_${this.orderNumber}.${this.state.type}`} href={this.state.url} className="btn-small col s5 offset-s2 white black-text waves-effect effect-dark">Download</a>}
                                            </div>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col s12">
                            <div className="card">
                                <div className="card-content">
                                    <div className="row">
                                        <h4 className="center">Alphabetical Report</h4>
                                    </div>
                                    <div className="row">
                                        <div className="row">
                                            <h5 className="center blue-text">Returnable</h5>
                                        </div>
                                        <table className="highlight">
                                            <thead>
                                                <tr>
                                                    <th style={{ padding: '4px' }}>NDC</th>
                                                    <th style={{ padding: '4px' }}>DEA Class</th>
                                                    <th style={{ padding: '4px' }}>Med Name</th>
                                                    <th style={{ padding: '4px' }}>Manufacturer</th>
                                                    <th style={{ padding: '4px' }}>Exp Date</th>
                                                    <th style={{ padding: '4px' }}>Lot Number</th>
                                                    <th style={{ padding: '4px' }}>Pkg Size</th>
                                                    <th style={{ padding: '4px' }}>Full Pkgs</th>
                                                    <th style={{ padding: '4px' }}>Partial Count</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {this.state.returnableList && this.state.returnableList.map(d => (
                                                    <tr key={`dr-${d.id}`}>
                                                        <td style={{ padding: '4px' }}>{d.ndc}</td>
                                                        <td style={{ padding: '4px' }}>{d.deaClass}</td>
                                                        <td style={{ padding: '4px' }}>{d.medName}</td>
                                                        <td style={{ padding: '4px' }}>{d.manufacturerName}</td>
                                                        <td style={{ padding: '4px' }}>{moment(d.expiration).format('MM/DD/YYYY')}</td>
                                                        <td style={{ padding: '4px' }}>{d.lotNumber}</td>
                                                        <td style={{ padding: '4px' }}>{d.packSize}</td>
                                                        <td style={{ padding: '4px' }}>{d.fullPackage}</td>
                                                        <td style={{ padding: '4px' }}>{d.partialPackage}</td>
                                                    </tr>
                                                ))}
                                            </tbody>
                                        </table>
                                    </div>
                                    <div className="row">
                                        <h5 className="center red-text">Non-Returnable</h5>
                                    </div>
                                    <div className="row">
                                        <table className="highlight">
                                            <thead>
                                                <tr>
                                                    <th style={{ padding: '4px' }}>NDC</th>
                                                    <th style={{ padding: '4px' }}>DEA Class</th>
                                                    <th style={{ padding: '4px' }}>Med Name</th>
                                                    <th style={{ padding: '4px' }}>Manufacturer</th>
                                                    <th style={{ padding: '4px' }}>Exp Date</th>
                                                    <th style={{ padding: '4px' }}>Lot Number</th>
                                                    <th style={{ padding: '4px' }}>Pkg Size</th>
                                                    <th style={{ padding: '4px' }}>Full Pkgs</th>
                                                    <th style={{ padding: '4px' }}>Partial Count</th>
                                                    <th style={{ padding: '4px' }}>Reason Code</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {this.state.nonReturnableList && this.state.nonReturnableList.map(d => (
                                                    <tr key={`dnr-${d.id}`}>
                                                        <td style={{ padding: '4px' }}>{d.ndc}</td>
                                                        <td style={{ padding: '4px' }}>{d.deaClass}</td>
                                                        <td style={{ padding: '4px' }}>{d.medName}</td>
                                                        <td style={{ padding: '4px' }}>{d.manufacturerName}</td>
                                                        <td style={{ padding: '4px' }}>{moment(d.expiration).format('MM/DD/YYYY')}</td>
                                                        <td style={{ padding: '4px' }}>{d.lotNumber}</td>
                                                        <td style={{ padding: '4px' }}>{d.packSize}</td>
                                                        <td style={{ padding: '4px' }}>{d.fullPackage}</td>
                                                        <td style={{ padding: '4px' }}>{d.partialPackage}</td>
                                                        <td style={{ padding: '4px' }}>{d.reason}</td>
                                                    </tr>
                                                ))}
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            }
        </div>
    )
}

export default LegacyRegularDetail;