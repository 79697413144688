import React from 'react';
import { Switch, Route } from 'react-router-dom';
import LegacyOverview from './legacyOverview';
import LegacyDetail from './legacyDetail';

const LegacyRouter = props => {
    sessionStorage.setItem('page', 'Legacy Orders');
    return (
        <Switch>
            <Route exact path="/legacy" component={LegacyOverview} />
            <Route exact path="/legacy/:orderNumber" component={LegacyDetail} />
        </Switch>
    )
}

export default LegacyRouter;