import React from 'react';
import { Route, Switch } from 'react-router-dom';
import creditsOverview from './creditsOverview';
import creditsDetail from './creditDetail';

const CreditsRouter = props => {
    sessionStorage.setItem('page', 'Credits');
    return (
        <Switch>
            <Route exact path="/credits" component={creditsOverview} />
            <Route exact path="/credits/:orderNumber" component={creditsDetail} />
        </Switch>
    )
}

export default CreditsRouter;