import Axios from 'axios';
import React from 'react';
import moment from 'moment';
import M from 'materialize-css';
import { Link, withRouter } from 'react-router-dom';
import Chart from 'chart.js';

class OrderOverview extends React.Component {
    constructor(props) {
        super(props);
        this.state = this.initState();
        this.authData = JSON.parse(window.sessionStorage.getItem('auth_data'));
        this.pharmacies = JSON.parse(window.sessionStorage.getItem('pharmacies'));
        this.barChart = null;
        this.totalDoughnut = null;
        this.reasonDoughnut = null;
        this.manufacturerDoughnut = null;
        this.clearTimer = null;
        this.resizeWait = true;
        this.windowSize = window.innerWidth;
        this.blues = ['#0d47a1', '#1565c0', '#1976d2', '#1e88e5', '#2196f3', '#42a5f5', '#64b5f6', '#90caf9', '#bbdefb', '#e3f2fd', '#82b1ff'];
    }

    initState = () => ({
        orders: null,
        orderHistory: {},
        pharmacyId: '0',
        gettingOrders: false,
        loadingDoughnutChart: false,
        barchartOrders: null,
        barchartHeight: '',
        selectedOrderNumber: null,
        bargraphYears: null
    })

    componentDidMount = () => {
        this.getOrderDetail();
        this.initListeners();
        M.Modal.init(document.querySelectorAll('.modal'));
        M.FormSelect.init(document.querySelectorAll('select'));
        this.totalDoughnut = new Chart(document.querySelector('#totals-chart'), { type: 'doughnut', options: { cutoutPercentage: 80, legend: { display: false } } });
        this.reasonDoughnut = new Chart(document.querySelector('#reason-chart'), { type: 'doughnut', options: { cutoutPercentage: 80, legend: { display: false } } });
        this.manufacturerDoughnut = new Chart(document.querySelector('#manufacturers-chart'), { type: 'horizontalBar', options: { legend: { display: false } } })
        this.clearTimer = setInterval(() => this.resizeWait = false, 500);
    }

    componentWillUnmount = () => {
        this.removeListeners();
        clearTimeout(this.clearTimer);
        document.querySelectorAll('.modal').forEach(modal => {
            const m = M.Modal.getInstance(modal);
            if (m)
                m.destroy();
        });
        document.querySelectorAll('select').forEach(el => {
            const s = M.FormSelect.getInstance(el);
            if (s)
                s.destroy();
        })

        if (this.barChart) { this.barChart.destroy() };
        if (this.reasonDoughnut) { this.reasonDoughnut.destroy() };
        if (this.manufacturerDoughnut) { this.manufacturerDoughnut.destroy() };
        if (this.totalDoughnut) { this.totalDoughnut.destroy() };
    }

    initListeners = () => {
        window.addEventListener('resize', this.resizeListener);
    }

    removeListeners = () => {
        window.removeEventListener('resize', this.resizeListener);
    }

    resizeListener = e => {
        const resizeNotWarranted = Math.abs(this.windowSize - window.innerWidth) < 100;

        if (this.resizeWait || resizeNotWarranted)
            return;

        this.resizeWait = true;
        this.windowSize = window.innerWidth;
        this.barChart.destroy();
        this.initBarGraph(document.querySelector('#bg-years-select').value);
    }


    initBarGraph = (year) => {
        const thisYear = JSON.parse(JSON.stringify(this.state.orders)).filter(o => {
            return moment(o.orderDate, 'YYYY-MM-DD').year() === parseInt(year)
        });

        this.setState({ barchartOrders: thisYear }, () => {

            const ctx = document.querySelector('#regular-orders-overview-bar-chart');
            if (this.barChart) { this.barChart.destroy() };
            this.barChart = new Chart(ctx, {
                type: 'bar',
                data: {
                    labels: thisYear.map(o => o.orderNumber),
                    datasets: [{
                        label: 'Estimated Value',
                        data: thisYear.map(o => o.totalEstimatedValue),
                        backgroundColor: thisYear.map(o => o.orderNumber === this.state.selectedOrderNumber ? '#24a064' : '#00e676'),
                        hoverBackgroundColor: '#24a064',
                        borderWidth: 1,
                        maxBarThickness: 60,
                        minBarLength: 12,
                    }]
                },
                options: {
                    responsive: true,
                    maintainAspectRatio: true,
                    aspectRatio: window.innerWidth > 992 ? 6 : 2,
                    legend: { display: false },
                    onClick: e => this.barChartClick(e, thisYear),
                    onHover: function (e) {
                        const arr = this.getElementAtEvent(e);
                        if (arr.length) { e.target.style.cursor = 'pointer' }
                        else { e.target.style.cursor = 'default' }
                    },
                }
            });
        })
        this.getDoughnutDeets(this.state.selectedOrderNumber)
    }

    barChartClick = (e, thisYear) => {
        const arr = this.barChart.getElementAtEvent(e);
        if (arr.length) {
            this.setState({ selectedOrderNumber: thisYear[arr[0]._index].orderNumber }, () => {

                this.barChart.data.datasets[0].backgroundColor = this.state.barchartOrders.map(o => o.orderNumber === this.state.selectedOrderNumber ? '#24a064' : '#00e676aa');
                this.barChart.update();

                const orderNumber = thisYear[arr[0]._index].orderNumber;
                this.getDoughnutDeets(orderNumber);
            })
        }
    }

    getDoughnutDeets = orderNumber => {
        this.setState({ loadingDoughnutCharts: true }, () => {
            Axios.post('/api/v1/customerreports/regularorders/overview/chart/doughnut', {
                ...this.authData,
                orderNumber
            })
                .then(result => {
                    this.assortedDoughnuts(result.data)
                })
                .catch(err => {
                    console.log(err);
                    if (err && err.response && err.response.status === 401) {
                        sessionStorage.clear();
                        sessionStorage.setItem('logged_out', true);
                        this.props.history.push('/');
                    }
                    else {
                        M.toast({ html: 'An unknown error occurred please try again.' })
                    }
                })
                .finally(() => this.setState({ loadingDoughnutCharts: false }))
        })

    }

    assortedDoughnuts = arr => {
        let totalReturnableValue = 0;
        let totalNonReturnableValue = 0;
        const reasonCodes = {};
        const manufacturers = {};

        arr.forEach(row => {
            if (row.nonReturnableReasonCodeId) {
                totalNonReturnableValue += row.estimatedReturnValue;

                if (reasonCodes[row.nonReturnableReasonCodeId])
                    reasonCodes[row.nonReturnableReasonCodeId] += row.estimatedReturnValue;
                else
                    reasonCodes[row.nonReturnableReasonCodeId] = 0 + row.estimatedReturnValue;
            }
            else {
                totalReturnableValue += row.estimatedReturnValue;

                if (manufacturers[row.manufacturerName])
                    manufacturers[row.manufacturerName] += row.estimatedReturnValue;
                else
                    manufacturers[row.manufacturerName] = 0 + row.estimatedReturnValue;

            }
        })
        this.freshDoughnuts(totalReturnableValue, totalNonReturnableValue, reasonCodes, manufacturers);
    }

    freshDoughnuts = (totalReturnableValue, totalNonReturnableValue, reasonCodes, manufacturers) => {
        this.totalDoughnut.data.labels = ['Returnable', 'Non-Returnable'];
        this.totalDoughnut.data.datasets = [{
            backgroundColor: ['#43a047', '#ccc'],
            data: [totalReturnableValue.toFixed(2), totalNonReturnableValue.toFixed(2)],

        }];
        this.totalDoughnut.update();

        this.reasonDoughnut.data.labels = Object.keys(reasonCodes).map(k => k);
        this.reasonDoughnut.data.datasets = [{
            label: null,
            backgroundColor: this.blues,
            data: Object.values(reasonCodes).map(v => parseFloat(v).toFixed(2))
        }]
        this.reasonDoughnut.update();

        this.manufacturerDoughnut.data.labels = Object.keys(manufacturers).map(k => k);
        this.manufacturerDoughnut.data.datasets = [{
            backgroundColor: '#00897b',
            data: Object.values(manufacturers).map(v => parseFloat(v).toFixed(2)),
            maxBarThickness: 20,
            minBarLength: 8
        }]
        this.manufacturerDoughnut.update();
    }


    getOrderDetail = () => {
        this.setState({ gettingOrders: true }, () => {
            Axios.post('/api/v1/customerreports/allorders', {
                ...this.authData,
                pharmacyId: this.state.pharmacyId
            })
                .then(result => {
                    const orders = JSON.parse(JSON.stringify(result.data));

                    orders.forEach(order => {
                        if (order && order.statuses) {
                            order.statuses.forEach(status => {
                                const { firstName, lastName } = status.employee;
                                const name = firstName ? `${firstName} ${lastName}` : '';
                                const date = status.timeStamp ? status.timeStamp : '';

                                switch (status.status) {
                                    case 'Representative On-Site':
                                        order.repName = name;
                                        order.orderDate = date;
                                        break;
                                    case 'Received':
                                        order.dateReceived = date;
                                        break;
                                    case 'Sorted':
                                        order.sortedBy = name;
                                        order.dateSorted = date;
                                        break;
                                    case 'Shipped to Manufacturer':
                                        order.debitMemoDate = date;
                                        break;
                                    default:
                                        return;
                                }
                            })
                        }
                    })
                    orders.forEach(o => {
                        if (o.statuses)
                            o.statuses.sort((a, b) => {
                                const timeA = new Date(a.timeStamp);
                                const timeB = new Date(b.timeStamp);
                                return timeA - timeB;
                            })
                    });
                    const yearsObj = {};
                    orders.forEach(o => yearsObj[moment(o.orderDate, 'YYYY-MM-DD').year()] = 1);
                    const bargraphYears = Object.keys(yearsObj).map(y => parseInt(y)).sort().reverse().filter(x => x);


                    this.setState({ orders, activeOrder: orders[0], selectedOrderNumber: orders[0].orderNumber, bargraphYears }, () => {
                        M.FormSelect.init(document.querySelector('#bg-years-select'));
                        this.initBarGraph(bargraphYears[0]);
                    });
                })
                .catch(err => {
                    console.log('ERR: ', err);
                    if (err && err.response && err.response.status === 401) {
                        sessionStorage.clear();
                        sessionStorage.setItem('logged_out', true);
                        this.props.history.push('/');
                    }
                    else {
                        M.toast({ html: 'An unknown error occurred please try again.' })
                    }
                })
                .finally(() => this.setState({ gettingOrders: false }))
        })
    }

    showHistory = order => {
        this.setState({ orderHistory: order }, () => {
            M.Modal.getInstance(document.querySelector('#history-modal')).open();
        })
    }

    pharmacyChange = e => this.setState({ pharmacyId: e.target.value }, this.getOrderDetail)
    bargraphChange = e => this.setState({ bargraphYear: e.target.value }, () => this.initBarGraph(this.state.bargraphYear));

    handleRowClick = (orderNumber) => {
        this.setState({ selectedOrderNumber: orderNumber }, () => {
            this.barChart.data.datasets[0].backgroundColor = this.state.barchartOrders.map(o => o.orderNumber === this.state.selectedOrderNumber ? '#24a064' : '#00e676aa');
            this.barChart.update();

            this.getDoughnutDeets(orderNumber)
        })
    }

    render = () => (
        <div className="offset-for-sidenav grey lighten-5" style={{ paddingTop: '12px' }}>
            <div className="row">
                <div className="col s12">
                    <div className="card">
                        <div className="card-content">
                            <div className="row">
                                <div className="input field col s4 m2 right">
                                    <select id="bg-years-select" onChange={this.bargraphChange}>
                                        {this.state.bargraphYears && this.state.bargraphYears.map(y => <option key={`year-${y}`} value={y}>{y}</option>)}
                                    </select>
                                </div>
                            </div>
                            <h5 className="center">Estimated Value by Order</h5>
                            <canvas id="regular-orders-overview-bar-chart" height={this.state.barchartHeight} />
                        </div>
                    </div>
                </div>
            </div>
            <div className="row">
                <div className="col m12 l9">
                    <div className="card" style={{ overflowX: 'auto' }}>
                        <div className="card-content">
                            {this.pharmacies && this.pharmacies.length > 1 &&
                                <div className="row">
                                    <div className="input-field col s12 m6">
                                        <select onChange={this.pharmacyChange}>
                                            <option value={0}>All Pharmacies</option>
                                            {this.pharmacies.map((p, index) => <option key={`opt-${index}`} value={p.id}>{p.name}</option>)}
                                        </select>
                                        <label>Pharmacy</label>
                                    </div>
                                </div>
                            }
                            <div className="row">
                                {!this.state.gettingOrders ? (
                                    <table className="highlight">
                                        <thead>
                                            <tr>
                                                <th>Order Number</th>
                                                <th>Order Date</th>
                                                <th>Status</th>
                                                <th className="hide-on-small-only">Rep Name</th>
                                                <th className="hide-on-small-only">Date Recieved</th>
                                                <th>History</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {this.state.orders && this.state.orders.map(o => (
                                                <tr key={`tr-${o.id}`} onClick={() => this.handleRowClick(o.orderNumber)} className={o.orderNumber === this.state.selectedOrderNumber ? 'grey lighten-2' : ''}>
                                                    <td style={{ padding: '6px' }} ><Link to={`/orderdetail/${o.orderNumber}`}>{o.orderNumber}</Link></td>
                                                    <td style={{ padding: '6px' }}>{o.orderDate ? moment(o.orderDate, 'YYYY-MM-DD').format('MM/DD/YY') : ''}</td>
                                                    <td style={{ padding: '6px' }}>{o.statuses ? o.statuses[o.statuses.length - 1].status : ''}</td>
                                                    <td className="hide-on-small-only" style={{ padding: '6px' }}>{o.repName}</td>
                                                    <td className="hide-on-small-only" style={{ padding: '6px' }}>{o.dateReceived ? moment(o.dateReceived, 'YYYY-MM-DD').format('MM/DD/YY') : ''}</td>
                                                    <td style={{ padding: '6px' }}><i style={{ cursor: 'pointer' }} className="material-icons" onClick={() => this.showHistory(JSON.parse(JSON.stringify(o)))}>history</i></td>
                                                </tr>
                                            ))}
                                        </tbody>
                                    </table>

                                )
                                    :
                                    <div className="progress">
                                        <div className="indeterminate" />
                                    </div>
                                }
                            </div>
                        </div>
                    </div>
                </div>
                <div className="col m12 l3">
                    <div className="row">
                        <div className="col s12">
                            <div className="card">
                                <div className="card-content">
                                    <h6 className="center">Estimated Value By Return Status</h6>
                                    <canvas id="totals-chart" height="174px" />
                                </div>
                            </div>
                        </div>
                        <div className="col s12">
                            <div className="card">
                                <div className="card-content">
                                    <h6 className="center">Non-Returnables By Reason</h6>
                                    <canvas id="reason-chart" height="174px" />
                                </div>
                            </div>
                        </div>
                        <div className="col s12">
                            <div className="card">
                                <div className="card-content">
                                    <h6 className="center">Estimated Value By Manufacturer</h6>
                                    <canvas id="manufacturers-chart" height="480" />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div id="history-modal" className="modal">
                <div className="modal-content">
                    <h5>Order #{this.state.orderHistory.orderNumber}</h5>
                    <table>
                        <thead>
                            <tr>
                                <th>Status</th>
                                <th>Time Stamp</th>
                            </tr>
                        </thead>
                        <tbody>
                            {this.state.orderHistory.statuses && this.state.orderHistory.statuses.map(status => (
                                <tr key={`status-tr-${status.id}`}>
                                    <td>{status.status}</td>
                                    <td>{status.timeStamp ? moment(status.timeStamp, 'YYYY-MM-DD').format('MM/DD/YY') : ''}</td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                </div>
            </div>

        </div>
    )
}

export default withRouter(OrderOverview);