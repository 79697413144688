import React from 'react';
import { Route, Switch } from 'react-router-dom';
import CertificateOfDestructionOverview from './certificateOfDestructionOverview';
import CertificateOfDestruction from './certificateOfDestruction';


const CertificateOfDestructionRouter = props => {
    sessionStorage.setItem('page', 'Destruction')
    return (
        <Switch>
            <Route exact path="/destruction" component={CertificateOfDestructionOverview} />
            <Route exact path="/destruction/:orderNumber" component={CertificateOfDestruction} />
        </Switch>
    )
}

export default CertificateOfDestructionRouter;