import Axios from 'axios';
import React from 'react';
import { Link } from 'react-router-dom';
import M from 'materialize-css';
import moment from 'moment';

class CertificateOfDestructionOverview extends React.Component {
    constructor(props) {
        super(props);
        this.state = this.initState();
        this.authData = JSON.parse(sessionStorage.getItem('auth_data'));
        this.pharmacies = JSON.parse(sessionStorage.getItem('pharmacies'));
    }

    initState = () => ({
        orders: null,
        gettingData: false,
        pharmacyId: '0'
    })

    componentDidMount = () => {
        this.getOrders();
        M.FormSelect.init(document.querySelectorAll('select'));
    }

    componentWillUnmount = () => {
        document.querySelectorAll('select').forEach(el => {
            const s = M.FormSelect.getInstance(el);
            if (s)
                s.destroy();
        });
    }

    getOrders = () => {
        this.setState({ gettingData: true }, () => {
            Axios.post('/api/v1/customerreports/orderswithdestruction', {
                ...this.authData,
                pharmacyId: this.state.pharmacyId
            })
                .then(result => {
                    this.setState({ orders: result.data })
                })
                .catch(err => {
                    if (err.response.status === 401) {
                        sessionStorage.clear();
                        sessionStorage.setItem('logged_out', true);
                        this.props.history.push('/');
                    }
                    else {
                        M.toast({ html: 'An unknown error occurred please try again.' })
                    }
                }).finally(() => this.setState({ gettingData: false }))
        })
    }

    pharmacyChange = e => this.setState({ pharmacyId: e.target.value }, this.getOrders)

    render = () => (
        <div className="offset-for-sidenav grey lighten-5" style={{ paddingTop: '15px', minHeight: "CALC(100vh - 128px" }}>
            <div className="row">
                <div className="col s12">
                    <div className="card">
                        <div className="card-content">
                            {this.pharmacies.length > 1 &&
                                <div className="row">
                                    <div className="input-field col s12 m6">
                                        <select onChange={this.pharmacyChange}>
                                            <option value={0}>All Pharmacies</option>
                                            {this.pharmacies.map((p, index) => <option key={`opt-${index}`} value={p.id}>{p.name}</option>)}
                                        </select>
                                        <label>Pharmacy</label>
                                    </div>
                                </div>
                            }
                            {this.state.gettingData ?
                                <div className="progress">
                                    <div className="indeterminate" />
                                </div>
                                :
                                <div className="row">
                                    {this.state.orders && this.state.orders.length ? (
                                        <table className="highlight">
                                            <thead>
                                                <tr>
                                                    <th>Order Number</th>
                                                    <th>Order Date</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {this.state.orders.map(o => (
                                                    <tr key={`tr-${o.id}`}>
                                                        <td style={{ padding: '6px' }}><Link to={`/destruction/${o.orderNumber}`}>{o.orderNumber}</Link></td>
                                                        <td style={{ padding: '6px' }}>{moment(o.statuses[0].timeStamp, 'YYYY-MM-DD').format('MM/DD/YY')}</td>
                                                    </tr>
                                                ))}
                                            </tbody>
                                        </table>
                                    ) : <h4>No Orders</h4>}
                                </div>
                            }
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default CertificateOfDestructionOverview;