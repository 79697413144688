import Axios from 'axios';
import React from 'react';
import { Link } from 'react-router-dom';
import M from 'materialize-css';

class IndatesOverview extends React.Component {
    constructor(props) {
        super(props);
        this.state = this.initState();
        this.authData = JSON.parse(sessionStorage.getItem('auth_data'));
        this.pharmacies = JSON.parse(sessionStorage.getItem('pharmacies'));
    }

    initState = () => ({
        indates: null,
        modalIndate: {},
        gettingIndates: false,
        pharmacyId: '0'
    })

    componentDidMount = () => {
        sessionStorage.setItem('page', 'Indates');
        this.getIndates();
        M.Modal.init(document.querySelectorAll('.modal'));
        M.FormSelect.init(document.querySelectorAll('select'));
    }

    componentWillUnmount = () => {
        document.querySelectorAll('.modal').forEach(modal => {
            const m = M.Modal.getInstance(modal);
            if (m)
                m.destroy();
        });
        document.querySelectorAll('select').forEach(el => {
            const s = M.FormSelect.getInstance(el);
            if (s)
                s.destroy();
        });
    }

    getIndates = () => {
        this.setState({ gettingIndates: true }, () => {
            Axios.post('/api/v1/customerreports/allindates', {
                ...this.authData,
                pharmacyId: this.state.pharmacyId
            })
                .then(result => this.setState({ indates: result.data }))
                .catch(err => {
                    if (err.response.status === 401) {
                        sessionStorage.clear();
                        sessionStorage.setItem('logged_out', true);
                        this.props.history.push('/');
                    }
                    else {
                        M.toast({ html: 'An unknown error occurred please try again.' })
                    }
                })
                .finally(() => this.setState({ gettingIndates: false }))
        })
    }

    showDetails = modalIndate => {
        this.setState({ modalIndate }, () => {
            M.Modal.getInstance(document.querySelector('#detail-modal')).open();
        })
    }

    pharmacyChange = e => this.setState({ pharmacyId: e.target.value }, this.getIndates)

    render = () => (
        <div className="offset-for-sidenav grey lighten-5" style={{ paddingTop: '12px', minHeight: 'CALC(100vh - 128px)' }}>
            <div className="row">
                <div className="col s12">
                    <div className="card" style={{overflow: 'auto'}}>
                        <div className="card-content">
                            {this.pharmacies.length > 1 &&
                                <div className="row">
                                    <div className="input-field col s12 m6">
                                        <select onChange={this.pharmacyChange}>
                                            <option value={0}>All Pharmacies</option>
                                            {this.pharmacies.map((p, index) => <option key={`opt-${index}`} value={p.id}>{p.name}</option>)}
                                        </select>
                                        <label>Pharmacy</label>
                                    </div>
                                </div>
                            }
                            {this.state.gettingIndates ?
                                <div className="progress">
                                    <div className="indeterminate" />
                                </div>
                                :
                                <table className="highlight">
                                    <thead>
                                        <tr>
                                            <th>Order Number</th>
                                            <th>Order Date</th>
                                            <th>NDC</th>
                                            <th>Med Name</th>
                                            <th>Manufacturer</th>
                                            <th>Earliest Return Date</th>
                                            <th>Details</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {this.state.indates && this.state.indates.map(i => (
                                            <tr key={`indates-tr-${i.id}`}>
                                                <td style={{ padding: '6px' }}><Link to={`/orderdetail/${i.orderNumber}`}>{i.orderNumber}</Link></td>
                                                <td style={{ padding: '6px' }}>{i.orderDate}</td>
                                                <td style={{ padding: '6px' }}>{i.ndc}</td>
                                                <td style={{ padding: '6px' }}>{i.medName}</td>
                                                <td style={{ padding: '6px' }}>{i.manufacturerName}</td>
                                                <td style={{ padding: '6px' }}>{i.nextProcessingDate}</td>
                                                <td style={{ padding: '6px' }}><i style={{ cursor: 'pointer' }} className="material-icons" onClick={() => this.showDetails(i)}>more_horiz</i></td>
                                            </tr>
                                        ))}
                                    </tbody>
                                </table>
                            }
                            <div id="detail-modal" className="modal">
                                <div className="modal-content">
                                    <div className="row">
                                        <h5>{this.state.modalIndate.medName} - <Link to={`/orderdetail/${this.state.modalIndate.orderNumber}`}>{this.state.modalIndate.orderNumber}</Link></h5>
                                    </div>
                                    <div className="row">
                                        <p><b>Package Size:</b> {this.state.modalIndate.packSize}</p>
                                        <p><b>Full Packages:</b> {this.state.modalIndate.fullPackage}</p>
                                        <p><b>Partial Count:</b> {this.state.modalIndate.partialPackage}</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default IndatesOverview;