import Axios from 'axios';
import React from 'react';
import M from 'materialize-css';

class Accounts extends React.Component {
    constructor(props) {
        super(props);
        this.authData = JSON.parse(window.sessionStorage.getItem('auth_data'));
        this.pharmacyList = JSON.parse(window.sessionStorage.getItem('pharmacies'));
        this.state = this.initState()
    }

    initState = () => ({
        loading: false,
        creatingUser: false,
        accounts: null,
        values: this.initValues()
    })

    initValues = () => ({
        firstName: '',
        lastName: '',
        title: '',
        emailAddress: '',
        phone: '',
        customerNumber: this.pharmacyList ? this.pharmacyList[0].customerNumber : ''
    })

    onChange = e => {
        e.persist();
        this.setState(p => ({ values: { ...p.values, [e.target.id]: e.target.value }, msg: '' }))
    }

    componentDidMount = () => {
        this.getAccounts();
        M.Modal.init(document.querySelectorAll('.modal'), { dismissible: false });
        M.FormSelect.init(document.querySelectorAll('select'));
    }

    getAccounts = () => {
        this.setState({ loading: true }, () => {
            Axios.post('/api/v1/customerreports/useraccounts', this.authData)
                .then(result => this.setState({ accounts: result.data }))
                .catch(err => {
                    if (err.response.status === 401) {
                        sessionStorage.clear();
                        sessionStorage.setItem('logged_out', true);
                        this.props.history.push('/');
                    }
                    else {
                        M.toast({ html: err.response ? err.response.data.msg : 'An unknown error occurred please try again.' })
                    }
                })
                .finally(() => this.setState({ loading: false }))
        })
    }

    editAccount = editAccount => {
        this.setState({ editAccount }, () => {
            M.Modal.getInstance(document.querySelector('#edit-acct-modal')).open();
        })
    }

    cancelEdit = e => {
        e.preventDefault();

        this.setState({ editAccount: null }, () => {
            M.Modal.getInstance(document.querySelector('#edit-acct-modal')).close();
        })
    }

    revokeAccess = (e, pharmacy) => {
        e.preventDefault();
        console.log(pharmacy);

        Axios.post('/api/v1/customerreports/revoke', {
            ...this.authData,
            customerAuthId: this.state.editAccount.customerAuthId,
            customerNumber: pharmacy.customerNumber
        })
            .then(result => {
                this.getAccounts();
                M.Modal.getInstance(document.querySelector('#edit-acct-modal')).close();
                M.toast({ html: `User Access Revoked for ${pharmacy.name}` })
            })
            .catch(err => {
                if (err.response.status === 401) {
                    sessionStorage.clear();
                    sessionStorage.setItem('logged_out', true);
                    this.props.history.push('/');
                }
                else {
                    M.toast({ html: err.response ? err.response.data.msg : 'An unknown error occurred please try again.' })
                }
            })
    }

    grantAccess = (e, pharmacy) => {
        e.preventDefault();

        Axios.post('/api/v1/customerreports/grant', {
            ...this.authData,
            customerAuthId: this.state.editAccount.customerAuthId,
            customerNumber: pharmacy.customerNumber
        })
            .then(result => {
                this.getAccounts();
                M.Modal.getInstance(document.querySelector('#edit-acct-modal')).close();
                M.toast({ html: `User Access Granted for ${pharmacy.name}` })
            })
            .catch(err => {
                if (err.response.status === 401) {
                    sessionStorage.clear();
                    sessionStorage.setItem('logged_out', true);
                    this.props.history.push('/');
                }
                else {
                    M.toast({ html: err.response ? err.response.data.msg : 'An unknown error occurred please try again.' })
                }
            })
    }

    removeUser = e => {
        e.preventDefault();
        console.log(this.state);
        const bool = window.confirm(`You are about to delete ${this.state.editAccount.firstName} ${this.state.editAccount.lastName}. Are you sure?`);
        if (bool) {

            M.Modal.getInstance(document.querySelector('#edit-acct-modal')).close();

            Axios.post('/api/v1/customerreports/deleteuser', {
                ...this.authData,
                customerAuthId: this.state.editAccount.customerAuthId
            })
                .then(result => {
                    M.toast({ html: 'User Deleted' });
                    this.getAccounts();
                    this.setState({ editAccount: null })
                })
                .catch(err => {
                    if (err.response.status === 401) {
                        sessionStorage.clear();
                        sessionStorage.setItem('logged_out', true);
                        this.props.history.push('/');
                    }
                    else {
                        M.toast({ html: err.response ? err.response.data.msg : 'An unknown error occurred please try again.' })
                    }
                })
        }
    }

    openAddModal = e => {
        e.preventDefault();
        console.log(M.Modal.getInstance(document.querySelector('#add-user-modal')));
        M.Modal.getInstance(document.querySelector('#add-user-modal')).open();
    }

    cancelAddUser = e => {
        e.preventDefault();
        this.setState({ values: this.initValues(), msg: '' }, () => {
            M.Modal.getInstance(document.querySelector('#add-user-modal')).close();
        })
    }

    addUser = e => {
        e.preventDefault();
        const { firstName, lastName, customerNumber, emailAddress } = this.state.values;
        let msg = '';

        if (!firstName)
            msg = 'First Name is required';
        else if (!lastName)
            msg = 'Last Name is required';
        else if (!customerNumber)
            msg = 'Please choose a pharmacy from the list';
        else if (!emailAddress)
            msg = 'Email Address is required';

        if (msg) { return this.setState({ msg }) }
        else {
            this.setState({ creatingUser: true }, () => {
                Axios.post('/api/v1/customerreports/createuser', {
                    ...this.authData,
                    ...this.state.values,
                    username: this.state.values.emailAddress
                })
                    .then(result => {
                        this.getAccounts()
                        M.toast({ html: `A sign-up email has been sent to ${this.state.values.emailAddress}` })
                        M.Modal.getInstance(document.querySelector('#add-user-modal')).close();
                        this.setState({ values: this.initValues() })
                    })
                    .catch(err => {
                        if (err.response.status === 401) {
                            sessionStorage.clear();
                            sessionStorage.setItem('logged_out', true);
                            this.props.history.push('/');
                        }
                        else {
                            M.toast({ html: err.response ? err.response.data.msg : 'An unknown error occurred please try again.' })
                        }
                    })
                    .finally(() => this.setState({ creatingUser: false }))
            })
        }
    }

    render = () => (
        <div className="col s12">
            {this.state.accounts && (
                <div>
                    <div className="card">
                        <div className="card-content">
                            <div className="row">
                                <table className="highlight">
                                    <thead>
                                        <tr>
                                            <th style={{ padding: '6px' }}>First Name</th>
                                            <th style={{ padding: '6px' }}>Last Name</th>
                                            <th style={{ padding: '6px' }}>Title</th>
                                            <th style={{ padding: '6px' }}>Phone</th>
                                            <th style={{ padding: '6px' }}>Email</th>
                                            <th style={{ padding: '6px' }}></th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {this.state.accounts && this.state.accounts.map(a => (
                                            <tr key={`acct-${a.id}`}>
                                                <td style={{ padding: '6px' }}>{a.firstName}</td>
                                                <td style={{ padding: '6px' }}>{a.lastName}</td>
                                                <td style={{ padding: '6px' }}>{a.title}</td>
                                                <td style={{ padding: '6px' }}>{a.phone}</td>
                                                <td style={{ padding: '6px' }}>{a.emailAddress}</td>
                                                <td style={{ padding: '6px' }}><i className="material-icons" style={{ cursor: 'pointer' }} onClick={() => this.editAccount(a)}>more_horiz</i></td>
                                            </tr>
                                        ))}
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col s12 m4 l2 left">
                            <a href="/" className="btn-small blue white-text waves-effect waves-light col s12" onClick={this.openAddModal}>Create New User</a>
                        </div>
                    </div>
                </div>
            )}
            <div id="edit-acct-modal" className="modal">
                <div className="modal-content" style={{ minHeight: '300px', display: 'flex', flexDirection: 'column', justifyContent: 'space-between' }}>
                    {this.state.editAccount && (
                        <div className="row">
                            <table>
                                <thead>
                                    <tr>
                                        <th style={{ padding: '4px' }}>Pharmacy</th>
                                        <th style={{ padding: '4px' }}>Permissions</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {this.pharmacyList && this.pharmacyList.map(p => {
                                        let isGranted = false;
                                        if (this.state.editAccount.pharmacies)
                                            this.state.editAccount.pharmacies.forEach(ph => { if (ph.id === p.id) { isGranted = true } })

                                        return (
                                            <tr key={`ph-${p.id}`}>
                                                <td style={{ padding: '4px' }}>{p.name}</td>
                                                <td style={{ padding: '4px' }}>{
                                                    isGranted ?
                                                        <a href="/" onClick={e => this.revokeAccess(e, p)}>Revoke Access</a>
                                                        :
                                                        <a href="/" onClick={e => this.grantAccess(e, p)}>Grant Access</a>
                                                }</td>
                                            </tr>
                                        )
                                    })}
                                </tbody>
                            </table>
                        </div>
                    )}
                    <div className="row">
                        <div className="col s12 m3 right">
                            <a href="/" className="btn-small white black-text col s12" onClick={this.cancelEdit}>Close</a>
                        </div>
                        <div className="col s12 m3 left">
                            <a href="/" className="btn-small red white-text col s12" onClick={this.removeUser}>Delete User</a>
                        </div>
                    </div>
                </div>
            </div>
            <div id="add-user-modal" className="modal">
                <div className="modal-content">
                    <div className="row">
                        <h5>New User</h5>
                    </div>
                    <div className="row">
                        <form>
                            <div className="row">
                                <div className="input-field col s12 m8 l4">
                                    <input id="firstName" type="text" onChange={this.onChange} value={this.state.values.firstName} />
                                    <label htmlFor="firstName">First Name</label>
                                </div>
                                <div className="input-field col s12 m8 l4">
                                    <input id="lastName" type="text" onChange={this.onChange} value={this.state.values.lastName} />
                                    <label htmlFor="lastName">Last Name</label>
                                </div>
                            </div>
                            <div className="row">
                                <div className="input-field col s12 m6">
                                    <select id="customerNumber" onChange={this.onChange} value={this.state.values.customerNumber}>
                                        {this.pharmacyList && this.pharmacyList.map(p => <option key={`cn-${p.id}`} value={p.customerNumber}>{p.name}</option>)}
                                    </select>
                                </div>
                                <div className="input-field col s12 m6 l2">
                                    <input id="title" type="text" onChange={this.onChange} value={this.state.values.title} />
                                    <label htmlFor="title">Title/Position</label>
                                </div>
                            </div>
                            <div className="row">
                                <div className="input-field col s12 m8 l6">
                                    <input id="emailAddress" type="text" onChange={this.onChange} value={this.state.values.emailAddress} />
                                    <label htmlFor="email">Email</label>
                                </div>
                                <div className="input-field col s12 m4 l2">
                                    <input id="phone" type="text" onChange={this.onChange} value={this.state.values.phone} />
                                    <label htmlFor="phone">Phone</label>
                                </div>
                            </div>
                        </form>
                    </div>
                    <div className="row">
                        <div className="col s12 m3 right">
                            <a href="/" className="btn-small white black-text col s12" onClick={this.cancelAddUser}>Close</a>
                        </div>
                        {this.state.creatingUser ?
                            <div className="col s12 m3 right">
                                <div class="preloader-wrapper small active">
                                    <div class="spinner-layer spinner-blue-only">
                                        <div class="circle-clipper left">
                                            <div class="circle"></div>
                                        </div><div class="gap-patch">
                                            <div class="circle"></div>
                                        </div><div class="circle-clipper right">
                                            <div class="circle"></div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            :
                            <div className="col s12 m3 right">
                                {this.state.msg ?
                                    <p className="red-text col s12">{this.state.msg}</p>
                                    :
                                    <a href="/" className="btn-small blue white-text col s12" onClick={this.addUser}>Create User</a>
                                }
                            </div>
                        }
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Accounts;