import React from 'react';
import Axios from 'axios';
import M from 'materialize-css';

class CreditsDetail extends React.Component {
    constructor(props) {
        super(props);
        this.state = this.initState();
        this.authData = JSON.parse(window.sessionStorage.getItem('auth_data'));
    }

    initState = () => ({
        credits: null,
        gettingCredits: false,
        gettingPrintables: false,
        pdfDownloadURL: null,
        csvDownloadURL: null,
        invoiceURL: null
    })

    componentDidMount = async () => {
        const pathArr = window.location.pathname.split('/')
        const orderNumber = pathArr[pathArr.length - 1];
        console.log(orderNumber);
        const isDestructionOnly = await this.getIsDestruction(orderNumber);
        if (isDestructionOnly) {
            return this.setState({ isDestructionOnly })
        }
        this.getCredits();
        this.getPrintables();
    }

    getIsDestruction = orderNumber => new Promise((resolve, reject) => {
        Axios.post('/api/v1/customerreports/isdestructiononlybyordernumber', { ...this.authData, orderNumber })
            .then(result => this.setState({ isDestructionOnly: result.data.isDestructionOnly }, () => {
                resolve(result.data.isDestructionOnly);
            }))
            .catch(err => {
                if (err.response.status === 401) {
                    sessionStorage.clear();
                    sessionStorage.setItem('logged_out', true);
                    this.props.history.push('/');
                }
                else {
                    M.toast({ html: 'An unknown error occurred please try again.' })
                }
                reject(false);
            })
    })

    getCredits = () => {
        this.setState({ gettingCredits: true }, () => {
            Axios.post('/api/v1/customerreports/credits/detail', {
                ...this.authData,
                orderNumber: this.props.match.params.orderNumber
            })
                .then(result => this.setState({ credits: result.data }))
                .catch(err => {
                    console.log(err);
                    if (err.response.status === 401) {
                        sessionStorage.clear();
                        sessionStorage.setItem('logged_out', true);
                        this.props.history.push('/');
                    }
                    else {
                        M.toast({ html: 'An unknown error occurred please try again.' })
                    }
                })
                .finally(() => this.setState({ gettingCredits: false }))
        })
    }

    getPrintables = () => {
        const orderNumber = this.props.match.params.orderNumber;
        //const orderHeaderId = this.props.location.state.orderHeaderId;

        this.setState({ gettingPrintables: true }, () => {
            const pdfPromise = Axios.post('/api/v1/customerreports/credits/detail/pdf', { ...this.authData, orderNumber }, { responseType: 'blob' })
                .then(result => {
                    const url = window.URL.createObjectURL(new Blob([result.data]));
                    this.setState({ pdfDownloadURL: url })
                })
                .catch(err => {
                    if (err.response.status === 401) {
                        sessionStorage.clear();
                        sessionStorage.setItem('logged_out', true);
                        this.props.history.push('/');
                    }
                    else {
                        M.toast({ html: 'An unknown error occurred please try again.' })
                    }
                })

            const csvPromise = Axios.post('/api/v1/customerreports/credits/detail/csv', { ...this.authData, orderNumber }, { responseType: 'blob' })
                .then(result => {
                    const url = window.URL.createObjectURL(new Blob([result.data], { type: 'text/csv;charset=utf-8' }));
                    this.setState({ csvDownloadURL: url })
                })
                .catch(err => {
                    if (err.response.status === 401) {
                        sessionStorage.clear();
                        sessionStorage.setItem('logged_out', true);
                        this.props.history.push('/');
                    }
                    else {
                        M.toast({ html: 'An unknown error occurred please try again.' })
                    }
                })

            // const invoicePromise = Axios.post('/api/v1/customerreports/invoice', { ...this.authData, orderHeaderId }, { responseType: 'blob' })
            //     .then(result => {
            //         const url = window.URL.createObjectURL(new Blob([result.data]));
            //         this.setState({ invoiceURL: url })
            //     })
            //     .catch(err => {
            //         if (err.response.status === 401) {
            //             sessionStorage.clear();
            //             sessionStorage.setItem('logged_out', true);
            //             this.props.history.push('/');
            //         }
            //         else {
            //             M.toast({ html: 'An unknown error occurred please try again.' })
            //         }
            //     })

            Promise.all([pdfPromise, csvPromise, /*invoicePromise*/]).then(() => this.setState({ gettingPrintables: false }))
        })
    }

    render = () => (
        <div className="offset-for-sidenav">
            <div className="row">
                <h4 className="center">Credits: {this.props.match.params.orderNumber}</h4>
                {this.state.isDestructionOnly ? <h5 className="center orange" style={{ fontWeight: '600' }}>THIS ORDER IS DESTRUCTION ONLY</h5> : null}
            </div>
            {!this.state.gettingPrintables ? (
                <div className="row">
                    {!this.state.isDestructionOnly &&
                        <div className="col s12 m4" style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
                            <a href={this.state.pdfDownloadURL} download={`${this.props.match.params.orderNumber}_credits.pdf`}>DOWNLOAD PDF</a>
                            <a href={this.state.csvDownloadURL} download={`${this.props.match.params.orderNumber}_credits.csv`}>DOWNLOAD CSV</a>
                            {/* <a href={this.state.invoiceURL} download={`${this.props.match.params.orderNumber}_invoice.pdf`}>DOWNLOAD INVOICE</a> */}
                        </div>
                    }
                </div>
            ) : (
                <div className="row">
                    <div className="preloader-wrapper small active">
                        <div className="spinner-layer spinner-blue-only">
                            <div className="circle-clipper left">
                                <div className="circle"></div>
                            </div><div className="gap-patch">
                                <div className="circle"></div>
                            </div><div className="circle-clipper right">
                                <div className="circle"></div>
                            </div>
                        </div>
                    </div>
                </div>)}

            <div style={{ overflowX: 'auto' }}>
                <table>
                    <thead>
                        <tr>
                            <th>Manufacturer</th>
                            <th style={{ textAlign: 'right' }}>Projected Value</th>
                            <th style={{ textAlign: 'right' }}>Actual Value</th>
                        </tr>
                    </thead>
                    <tbody>
                        {this.state.credits && this.state.credits.map(c => (
                            <tr key={`tr-${c.id}`}>
                                <td style={{ padding: '4px' }}>{c.manufacturer}</td>
                                <td style={{ padding: '4px', textAlign: 'right' }}>{parseFloat(c.projectedValue || 0).toFixed(2)}</td>
                                <td style={{ padding: '4px', textAlign: 'right' }}>{c.actualValue ? parseFloat(c.actualValue).toFixed(2) : '---'}</td>
                            </tr>
                        ))}
                        {this.state.credits && (
                            <tr>
                                <td style={{ padding: '6px' }}><b>Total: </b></td>
                                <td style={{ padding: '6px', textAlign: 'right' }}><b>{this.state.credits.reduce((total, credit) => total + parseFloat(credit.projectedValue || 0), 0).toFixed(2)}</b></td>
                                <td style={{ padding: '6px', textAlign: 'right' }}><b>{this.state.credits.reduce((total, credit) => total + parseFloat(credit.actualValue || 0), 0).toFixed(2)}</b></td>
                            </tr>
                        )}
                    </tbody>
                </table>
                <small className="right" style={{ marginTop: '40px' }}><b style={{ fontWeight: 600 }}>---</b> Denotes that a credit is still outstanding</small>
            </div>
        </div>
    )
}

export default CreditsDetail;