import Axios from 'axios';
import React from 'react';
import M from 'materialize-css';

class CertificateOfDestruction extends React.Component {
    constructor(props) {
        super(props);
        this.state = this.initState();
        this.authData = JSON.parse(sessionStorage.getItem('auth_data'));
    }

    initState = () => ({
        destructionDetails: null,
        csvDownloadURL: '',
        pdfDownloadURL: '',
        loading: false,
        gettingDetails: true
    })

    componentDidMount = () => {
        const { orderNumber } = this.props.match.params;
        this.setState({ orderNumber });
        this.getDetails(orderNumber);
        this.getPrintables(orderNumber);
    }

    getDetails = orderNumber => {
        this.setState({ gettingDetails: true }, () => {
            Axios.post('/api/v1/customerreports/destruction', {
                ...this.authData,
                orderNumber
            })
                .then(result => this.setState({ destructionDetails: result.data.length ? result.data : null }))
                .catch(err => {
                    if (err.response && err.response.status === 401) {
                        sessionStorage.clear();
                        sessionStorage.setItem('logged_out', true);
                        this.props.history.push('/');
                    }
                    else {
                        M.toast({ html: 'An unknown error occurred please try again.' })
                    }
                })
                .finally(() => this.setState({ gettingDetails: false }))
        })
    }

    getPrintables = orderNumber => {
        this.setState({ loading: true })

        const pdfPromise = Axios.post('/api/v1/customerreports/destruction/pdf', { ...this.authData, orderNumber }, { responseType: 'blob' })
            .then(result => {
                const url = window.URL.createObjectURL(new Blob([result.data]));
                this.setState({ pdfDownloadURL: url })
            })
            .catch(err => {
                if (err.response && err.response.status === 401) {
                    sessionStorage.clear();
                    sessionStorage.setItem('logged_out', true);
                    this.props.history.push('/');
                }
                else {
                    M.toast({ html: 'An unknown error occurred please try again.' })
                }
            })
        const csvPromise = Axios.post('/api/v1/customerreports/destruction/csv', { ...this.authData, orderNumber }, { responseType: 'blob' })
            .then(result => {
                const url = window.URL.createObjectURL(new Blob([result.data], { type: 'text/csv;charset="utf-8' }));
                this.setState({ csvDownloadURL: url })
            })
            .catch(err => {
                if (err.response && err.response.status === 401) {
                    sessionStorage.clear();
                    sessionStorage.setItem('logged_out', true);
                    this.props.history.push('/');
                }
                else {
                    M.toast({ html: 'An unknown error occurred please try again.' })
                }
            })
        Promise.all([pdfPromise, csvPromise]).then(() => this.setState({ loading: false }))
    }


    render = () => (
        <div className="offset-for-sidenav">
            <div className="row">
                <h4 className="center">Certificate of Destruction - {this.state.orderNumber}</h4>
            </div>
            {!this.state.loading ? (
                <div className="row">
                    <div className="col s12 m4" style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-around' }}>
                        <a href={this.state.pdfDownloadURL} download={`destruction_${this.state.orderNumber}.pdf`}>DOWNLOAD PDF</a>
                        <a href={this.state.csvDownloadURL} download={`destruction_${this.state.orderNumber}.csv`}>DOWNLOAD CSV</a>
                    </div>
                </div>
            ) : (
                <div className="row">
                    <div className="preloader-wrapper small active">
                        <div className="spinner-layer spinner-blue-only">
                            <div className="circle-clipper left">
                                <div className="circle"></div>
                            </div><div className="gap-patch">
                                <div className="circle"></div>
                            </div><div className="circle-clipper right">
                                <div className="circle"></div>
                            </div>
                        </div>
                    </div>
                </div>)}
            <div className="row">
                <h4 className="center red-text"><b>Registrant Record of Controlled Substances Destroyed</b></h4>
            </div>
            <div className="row">
                <h5>A. REGISTRANT INFORMATION</h5>
            </div>
            <div className="row">
                <table className="container">
                    <tbody>
                        <tr>
                            <td style={{ padding: '6px' }} colSpan={2}><b>Registered Name:</b> Outdate Rx LLC</td>
                            <td style={{ padding: '6px' }}><b>DEA Registration Number:</b> RO0483468</td>
                        </tr>
                        <tr>
                            <td style={{ padding: '6px' }} colSpan={3}><b>Registered Address:</b> 390 Alabama St, Suite A</td>
                        </tr>
                        <tr>
                            <td style={{ padding: '6px' }}><b>City:</b> Redlands</td>
                            <td style={{ padding: '6px' }}><b>State:</b> CA</td>
                            <td style={{ padding: '6px' }}><b>Zip:</b> 92373</td>
                        </tr>
                        <tr>
                            <td style={{ padding: '6px' }}><b>Telephone:</b> (909)335-7071</td>
                            <td style={{ padding: '6px' }} colSpan={2}><b>Contact Name:</b> Jacob Record</td>
                        </tr>
                    </tbody>
                </table>
            </div>
            <div className="row" style={{ marginTop: '12px' }}>
                <h5>B. ITEMS DESTROYED</h5>
            </div>
            {this.state.gettingDetails ?
                <div className="progress">
                    <div className="indeterminate" />
                </div>
                :
                <div className="row">
                    <table>
                        <thead>
                            <tr>
                                <th>NDC</th>
                                <th>Lot Number</th>
                                <th>Med Name</th>
                                <th>Pkg Size</th>
                                <th>Full Pkg Count</th>
                                <th>Partial Pkg Count</th>
                                <th>Total Destroyed</th>
                            </tr>
                        </thead>
                        <tbody>
                            {this.state.destructionDetails && this.state.destructionDetails.map(row => (
                                <tr key={`tr-${row.id}`}>
                                    <td style={{ padding: '6px' }}>{row.ndc}</td>
                                    <td style={{ padding: '6px' }}>{row.lotNumber}</td>
                                    <td style={{ padding: '6px' }}>{row.medName}</td>
                                    <td style={{ padding: '6px' }}>{row.packSize}</td>
                                    <td style={{ padding: '6px' }}>{row.fullPackage}</td>
                                    <td style={{ padding: '6px' }}>{row.partialPackage}</td>
                                    <td style={{ padding: '6px' }}>{row.totalDestroyed}</td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                </div>
            }
            <div className="row" style={{ marginTop: '12px' }}>
                <h5>C. METHOD OF DESTRUCTION</h5>
            </div>
            <div className="row">
                <table className="container">
                    <tbody>
                        <tr>
                            <td style={{ padding: '6px' }}><b>Date of Destruction:</b> {this.state.destructionDetails ? this.state.destructionDetails[0].lastDateHandled : ''}</td>
                            <td style={{ padding: '6px' }} colSpan={2}><b>Method of Destruction:</b> Incineration</td>
                        </tr>
                        <tr>
                            <td style={{ padding: '6px' }} colSpan={3}><b>Business Name:</b> Outdate Rx LLC</td>
                        </tr>
                        <tr>
                            <td style={{ padding: '6px' }} colSpan={3}><b>Address:</b> 390 Alabama St, Suite A</td>
                        </tr>
                        <tr>
                            <td style={{ padding: '6px' }}><b>City:</b> Redlands</td>
                            <td style={{ padding: '6px' }}><b>State:</b> CA</td>
                            <td style={{ padding: '6px' }}><b>Zip:</b> 92373</td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
    )
}

export default CertificateOfDestruction;