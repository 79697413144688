import React from 'react';
import { Link } from 'react-router-dom';
import Axios from 'axios';
import M from 'materialize-css';
import moment from 'moment';


class LegacyOverview extends React.Component {
    constructor(props) {
        super(props);
        this.authData = JSON.parse(sessionStorage.getItem('auth_data'));
        this.state = this.initState();
    }

    initState = () => ({
        searching: false,
        orders: null,
        values: this.initValues(),
    })

    initValues = () => ({
        orderNumber: '',
        pharmacyName: '',
        orderDateStart: '',
        orderDateEnd: '',
    })

    componentDidMount = () => {
        this.search();
    }

    onChange = e => {
        const { id, value } = e.target;
        this.setState(p => ({ values: { ...p.values, [id]: value } }))
    }

    search = e => {
        if (e)
            e.preventDefault();

        this.setState({ searching: true }, () => {
            Axios.post('/api/v1/customerreports/legacy/all', {
                ...this.authData,
                ...this.state.values
            })
                .then(result => this.setState({ orders: result.data }))
                .catch(err => {
                    if (err.response.status === 401) {
                        sessionStorage.clear();
                        sessionStorage.setItem('logged_out', true);
                        this.props.history.push('/');
                    }
                    else {
                        M.toast({ html: 'An unknown error occurred please try again.' })
                    }
                })
                .finally(() => this.setState({ searching: false }))
        })
    }

    render = () => (
        <div className="offset-for-sidenav grey lighten-5" style={{ paddingTop: '12px' }}>
            <div className="row">
                <div className="col s12">
                    <div className="card">
                        <div className="card-content">
                            <div className="row">
                                <h5>Order Search</h5>
                            </div>
                            <div className="row">
                                <form>
                                    <div className="input-field col s12 m4 l2">
                                        <input id="orderNumber" type="text" onChange={this.onChange} value={this.state.values.orderNumber} />
                                        <label htmlFor="orderNumber">Order Number</label>
                                    </div>
                                    <div className="input-field col s12 m4 l2">
                                        <input id="pharmacyName" type="text" onChange={this.onChange} value={this.state.values.pharmacyName} />
                                        <label htmlFor="pharmacyName">Pharmacy Name</label>
                                    </div>
                                    <div className="input-field col s12 m4 l2">
                                        <input id="orderDateStart" type="date" onChange={this.onChange} value={this.state.values.orderDateStart} />
                                        <label htmlFor="orderDateStart">Order Date Start</label>
                                    </div>
                                    <div className="input-field col s12 m4 l2">
                                        <input id="orderDateEnd" type="date" onChange={this.onChange} value={this.state.values.orderDateEnd} />
                                        <label htmlFor="orderDateEnd">Order Date End</label>
                                    </div>
                                    <div className="input-field col s12 m4 offset-m2 l2 offset-l2">
                                        {this.state.searching ?
                                            <div className="col s2 offset-s5">
                                                <div className="preloader-wrapper small active">
                                                    <div className="spinner-layer spinner-blue-only">
                                                        <div className="circle-clipper left">
                                                            <div className="circle"></div>
                                                        </div><div className="gap-patch">
                                                            <div className="circle"></div>
                                                        </div><div className="circle-clipper right">
                                                            <div className="circle"></div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            :
                                            <button type="submit" className="btn-small blue white-text waves-effect col s12" onClick={this.search}>Search</button>
                                        }
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="row">
                <div className="col s12">
                    <div className="card">
                        <div className="card-content">
                            {this.state.searching ?
                                <div className="progress">
                                    <div className="indeterminate" />
                                </div>
                                :
                                <div>
                                    <div className="row">
                                        <h5 className="center">Orders</h5>
                                    </div>
                                    <div className="row">
                                        <table>
                                            <thead>
                                                <tr>
                                                    <th>Order Number</th>
                                                    <th>Pharmacy</th>
                                                    <th>Order Date</th>
                                                    <th>Date Sorted</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {this.state.orders && this.state.orders.map(o => (
                                                    <tr key={`r-${o.id}`}>
                                                        <td><Link to={{ pathname: `/legacy/${o.orderNumber}`, state: { order: o } }}>{o.orderNumber}</Link></td>
                                                        <td>{o.name}</td>
                                                        <td>{moment(o.orderDate).format('MM/DD/YYYY')}</td>
                                                        <td>{o.sortDate ? moment(o.sortDate).format('MM/DD/YYYY') : ''}</td>
                                                    </tr>
                                                ))}
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            }
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default LegacyOverview;