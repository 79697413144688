import React from 'react';
import { Switch, Route, Link } from 'react-router-dom';
import M from 'materialize-css';
import UserInfo from './userInfo.js';
import Accounts from './accounts.js';

class SettingsRouter extends React.Component {

    componentDidMount = () => {
        sessionStorage.setItem('page', 'Settings');
        M.Tabs.init(document.querySelectorAll('.tabs'));
    }

    redirect = e => {
        const route = e.target.getAttribute('data');
        this.props.history.push(route);
    }

    render = () => (
        <div className="offset-for-sidenav">
            <div className="row">
                <div className="col s12">
                    <ul className="tabs">
                        <li className="tab col s3"><Link to="/settings" data="/settings" onClick={this.redirect}>User Info</Link></li>
                        <li className="tab col s3"><Link to="/settings/accounts" data="/settings/accounts" onClick={this.redirect}>Accounts</Link></li>
                    </ul>
                </div>
            </div>
            <Switch>
                <Route exact path="/settings" component={UserInfo} />
                <Route exact path="/settings/accounts" component={Accounts} />
            </Switch>
        </div>
    )
}

export default SettingsRouter;