import React from 'react';
import Axios from 'axios';
import { detect } from 'detect-browser';

class Login extends React.Component {
    constructor(props) {
        super(props);
        this.state = this.initState();
        this.isLogMet = this.getIsLogmet();
        this.isPharmaVerse = this.getIsPharmaVerse();
    }

    getIsLogmet = () => {
        const [domain] = window.location.toString().split('.com');

        if (!domain)
            return false;
        else if (domain.toLowerCase().search('logmet') !== -1)
            return true
        else
            return false
    }

    getIsPharmaVerse = () => {
        const [domain] = window.location.toString().split('.com');
        console.log(domain);

        if (!domain)
            return false;
        else if (domain.toLowerCase().search('pharmaverse') !== -1)
            return true
        else
            return false
    }

    initState = () => ({
        username: '',
        password: '',
        waiting: false,
        forgotPassword: false,
        emailAddress: '',
        err: false,
        _RESET_PASSWORD_: false
    })

    onChange = e => this.setState({ [e.target.name]: e.target.value })

    submit = e => {
        e.preventDefault();
        const browserInfo = detect();

        this.setState({ waiting: true, err: '' }, () => {
            Axios.post('/api/v1/customerreports/login', {
                username: this.state.username,
                password: this.state.password,
                browserInfo
            })
                .then(result => {
                    if (result.data === '_RESET_PASSWORD_') { return this.setState({ _RESET_PASSWORD_: true }) }
                    const { token, pharmacies, sessionId, username, firstName, lastName, title } = result.data;
                    if (token && pharmacies && sessionId && username) {
                        window.sessionStorage.clear();
                        window.sessionStorage.setItem('auth_data', JSON.stringify({ token, sessionId }));
                        window.sessionStorage.setItem('pharmacies', JSON.stringify(pharmacies));
                        window.sessionStorage.setItem('username', username);
                        window.sessionStorage.setItem('firstName', firstName);
                        window.sessionStorage.setItem('lastName', lastName);
                        window.sessionStorage.setItem('title', title);
                    }
                })
                .catch(err => {
                    console.log('ERR', err);
                    this.setState({ err: err.response.data })
                })
                .finally(() => this.setState({ waiting: false }, () => this.props.renderParent()))
        })

    }

    verifyEmail = e => {
        e.preventDefault();

        this.setState({ waiting: true, forgotEmailMsg: '' }, () => {
            Axios.post('/api/v1/customerreports/settemppassword', {
                email: this.state.emailAddress
            })
                .then(result => this.setState({ forgotEmailMsg: result.data, err: false }))
                .catch(err => this.setState({ forgotEmailMsg: err.response.data, err: true }))
                .finally(() => this.setState({ waiting: false }))
        })
    }

    saveNewPasswordAndLogin = e => {
        e.preventDefault();

        this.setState({ waiting: true }, () => {
            Axios.post('/api/v1/customerreports/resetpassword', {
                username: this.state.username,
                tempPassword: this.state.password,
                newPassword: this.state.resetNewPassword,
                repeatPassword: this.state.resetRepeatPassword
            })
                .then(result => {
                    const { token, pharmacies, sessionId, username } = result.data;
                    if (token && pharmacies && sessionId && username) {
                        window.sessionStorage.clear();
                        window.sessionStorage.setItem('auth_data', JSON.stringify({ token, sessionId }));
                        window.sessionStorage.setItem('pharmacies', JSON.stringify(pharmacies));
                        window.sessionStorage.setItem('username', username)
                    }
                })
                .catch(err => console.log(err.response.data))
                .finally(() => this.setState({ waiting: false }, this.props.renderParent))
        })
    }

    render = () => {
        if (this.state._RESET_PASSWORD_)
            return (
                <div className="container">
                    <div className="row center" style={{ marginTop: '20%' }}>
                        {this.isLogMet ?
                            <div>
                                <img className="responsive-img" src="/img/logmet_logo.jpg" alt="Logmet Logo" />
                                <span>Powered by: <span className="red-text" style={{ fontWeight: '600', fontSize: '1.1em' }}>Outdate Rx</span></span>
                            </div>
                            : this.isPharmaVerse ?
                                <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center' }} className="col offset-s3">
                                    <img className="responsive-img" src="/img/pharmaverse_logo.png" alt="Logmet Logo" style={{ maxHeight: '160px', width: 'auto' }} />
                                    <span>Powered by: <span className="red-text" style={{ fontWeight: '600', fontSize: '1.1em' }}>Outdate Rx</span></span>
                                </div>
                                :
                                <img className="responsive-img" src="/img/orx_pill.jpg" alt="Outdate Rx Pill Logo" />
                        }
                    </div>
                    <div className="row" style={{ marginTop: '40px' }}>
                        <form onSubmit={this.saveNewPasswordAndLogin}>
                            <div className="col s12 m8 offset-m2 xl4 offset-xl4">
                                <div className="card-panel white">
                                    <div className="row">
                                        <div className="input-field col s12">
                                            <input id="_resetNewPassword" name="resetNewPassword" type="password" value={this.state.resetNewPassword} onChange={this.onChange} />
                                            <label htmlFor="_resetNewPassword">New Password</label>
                                        </div>
                                        <div className="input-field col s12">
                                            <input id="_resetRepeatPassword" name="resetRepeatPassword" type="password" value={this.state.resetRepeatPassword} onChange={this.onChange} />
                                            <label htmlFor="_resetRepeatPassword">Repeat New Password</label>
                                        </div>
                                        <div className="input-field col s12">
                                            {this.state.waiting ?
                                                <div className="center">
                                                    <div className="preloader-wrapper small active">
                                                        <div className="spinner-layer spinner-blue-only">
                                                            <div className="circle-clipper left">
                                                                <div className="circle"></div>
                                                            </div><div className="gap-patch">
                                                                <div className="circle"></div>
                                                            </div><div className="circle-clipper right">
                                                                <div className="circle"></div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                :
                                                <div className="center">
                                                    <button type="submit" className="btn blue white-text">Submit</button>
                                                </div>
                                            }
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </form>
                    </div>
                </div >
            )
        else if (!this.state.forgotPassword)
            return (
                <div className="container">
                    <div className="row">
                        <h6 className="orange-text right">{sessionStorage.getItem('logged_out') ? 'You have been logged out' : ''}</h6>
                    </div>                    <div className="row center" style={{ marginTop: '20%' }}>
                        {this.isLogMet ?
                            <div>
                                <img className="responsive-img" src="/img/logmet_logo.jpg" alt="Logmet Logo" />
                                <span>Powered by: <span className="red-text" style={{ fontWeight: '600', fontSize: '1.1em' }}>Outdate Rx</span></span>
                            </div>
                            :
                            this.isPharmaVerse ?
                                <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center' }} className="col offset-s3">
                                    <img className="responsive-img" src="/img/pharmaverse_logo.png" alt="Logmet Logo" style={{ maxHeight: '160px', width: 'auto' }} />
                                    <span>Powered by: <span className="red-text" style={{ fontWeight: '600', fontSize: '1.1em' }}>Outdate Rx</span></span>
                                </div>
                                :
                                <img className="responsive-img" src="/img/orx_pill.jpg" alt="Outdate Rx Pill Logo" />
                        }                    </div>
                    <div className="row" style={{ marginTop: '40px' }}>
                        <div className="col s12 m8 offset-m2 xl4 offset-xl4">
                            <form onSubmit={this.submit}>
                                <div className="card-panel white">
                                    <div className="row">
                                        <div className="input-field col s12">
                                            <i className="material-icons prefix">account_circle</i>
                                            <input id="_username" name="username" type="text" value={this.state.username} onChange={this.onChange} />
                                            <label htmlFor="_username">Username (email)</label>
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="input-field col s12">
                                            <i className="material-icons prefix">vpn_key</i>
                                            <input id="_password" name="password" type="password" value={this.state.password} onChange={this.onChange} />
                                            <label htmlFor="_password">Passphrase</label>
                                        </div>
                                    </div>
                                    <div><span className="red-text">{this.state.err}</span></div>
                                    <div className="row">
                                        {this.state.waiting ?
                                            <div className="center">
                                                <div className="preloader-wrapper small active">
                                                    <div className="spinner-layer spinner-red-only">
                                                        <div className="circle-clipper left">
                                                            <div className="circle"></div>
                                                        </div><div className="gap-patch">
                                                            <div className="circle"></div>
                                                        </div><div className="circle-clipper right">
                                                            <div className="circle"></div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            :
                                            <div className="input-field col s12">
                                                <div className="center">
                                                    <button type="submit" className="btn waves-effect waves-light red white-text">Submit</button>
                                                </div>
                                            </div>
                                        }
                                    </div>
                                </div>
                            </form>
                            <div>
                                <a className="right" href="/" onClick={e => { e.preventDefault(); this.setState({ forgotPassword: true }) }}>Forgot password?</a>
                            </div>
                        </div>
                    </div>
                </div>
            )
        else
            return (
                <div className="container">
                    <div className="row center" style={{ marginTop: '20%' }}>
                        {this.isLogMet ?
                            <div>
                                <img className="responsive-img" src="/img/logmet_logo.jpg" alt="Logmet Logo" />
                                <span>Powered by: <span className="red-text" style={{ fontWeight: '600', fontSize: '1.1em' }}>Outdate Rx</span></span>
                            </div>
                            :
                            this.isPharmaVerse ?
                                <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center' }} className="col offset-s3">
                                    <img className="responsive-img" src="/img/pharmaverse_logo.png" alt="Logmet Logo" style={{ maxHeight: '160px', width: 'auto' }} />
                                    <span>Powered by: <span className="red-text" style={{ fontWeight: '600', fontSize: '1.1em' }}>Outdate Rx</span></span>
                                </div>
                                :
                                <img className="responsive-img" src="/img/orx_pill.jpg" alt="Outdate Rx Pill Logo" />
                        }                    </div>
                    <div className="row" style={{ marginTop: '40px' }}>
                        <div className="col s12 m8 offset-m2 xl4 offset-xl4">
                            <form onSubmit={this.verifyEmail}>
                                <div className="card-panel white">
                                    <div className="row">
                                        <span>Use the email address that you signed up with, and we'll send you an email with instructions to change your password</span>
                                    </div>
                                    <div className="row">
                                        <div className="input-field col s12">
                                            <i className="material-icons prefix">email</i>
                                            <input id="_emailAddress" name="emailAddress" type="text" value={this.state.emailAddress} onChange={this.onChange} />
                                            <label htmlFor="_emailAddress">Email Address</label>
                                        </div>
                                        <div className="input-field col s12">
                                            {this.state.waiting ?
                                                <div className="center">
                                                    <div className="preloader-wrapper small active">
                                                        <div className="spinner-layer spinner-blue-only">
                                                            <div className="circle-clipper left">
                                                                <div className="circle"></div>
                                                            </div><div className="gap-patch">
                                                                <div className="circle"></div>
                                                            </div><div className="circle-clipper right">
                                                                <div className="circle"></div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                :
                                                <div className="center">
                                                    <button type="submit" className="btn blue white-text">Submit</button>
                                                </div>
                                            }
                                        </div>
                                        <div className="col s12">
                                            <p className={`${this.state.err ? 'red' : 'green'}-text`}>{this.state.forgotEmailMsg}</p>
                                        </div>
                                    </div>
                                </div>
                            </form>
                            <div className="right">
                                <a href="/" onClick={e => { e.preventDefault(); this.setState({ forgotPassword: false }) }}>Back to Login</a>
                            </div>
                        </div>
                    </div>
                </div>
            )
    }
}

export default Login;