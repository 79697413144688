import React from 'react';
import M from 'materialize-css';
import { withRouter, Link } from 'react-router-dom';
import Axios from 'axios';

class InventoryOverview extends React.Component {
    constructor(props) {
        super(props);
        this.authData = JSON.parse(sessionStorage.getItem('auth_data'));
        this.pharmacies = JSON.parse(sessionStorage.getItem('pharmacies'));
        this.state = this.initState();
    }

    initState = () => ({
        rows: null,
        alias: '',
        pharmacyId: this.pharmacies[0].id,
        gettingInventories: false,
        showArchived: false
    })

    componentDidMount = () => {
        this.getInventories();
        M.Modal.init(document.querySelectorAll('.modal'), { dismissible: false });
        M.FormSelect.init(document.querySelectorAll('select'));
    }

    componentWillUnmount = () => {
        document.querySelectorAll('.modal').forEach(el => {
            const m = M.Modal.getInstance(el);
            if (m)
                m.destroy();
        });
        document.querySelectorAll('select').forEach(el => {
            const s = M.FormSelect.getInstance(el);
            if (s)
                s.destroy();
        });
    }

    onChange = e => this.setState({ [e.target.name]: e.target.value })

    getInventories = () => {
        this.setState({ gettingInventories: true }, () => {
            Axios.post('/api/v1/customerreports/inventory/header/read', {
                ...this.authData
            })
                .then(result => {
                    const active = [], archived = [];
                    result.data.forEach(row => row.isArchived ? archived.push(row) : active.push(row));
                    this.setState({ active, archived })
                })
                .catch(err => {
                    if (err.response.status === 401) {
                        sessionStorage.clear();
                        sessionStorage.setItem('logged_out', true);
                        this.props.history.push('/');
                    }
                    else {
                        M.toast({ html: 'An unknown error occurred please try again.' })
                    }
                })
                .finally(() => this.setState({ gettingInventories: false }))
        })
    }

    createInventory = e => {
        e.preventDefault();

        if (!this.state.alias) {
            return M.toast({ html: 'You must supply a nickname for your new inventory.' })
        }

        Axios.post('/api/v1/customerreports/inventory/header/create', {
            ...this.authData,
            pharmacyId: this.state.pharmacyId,
            alias: this.state.alias
        })
            .then(result => {
                const orderHeaderId = result.data.insertId;
                this.props.history.push(`/inventory/${orderHeaderId}`)
            })
            .catch(err => {
                if (err.response.status === 401) {
                    sessionStorage.clear();
                    sessionStorage.setItem('logged_out', true);
                    this.props.history.push('/');
                }
                else {
                    M.toast({ html: 'An unknown error occurred please try again.' })
                }
            })
    }

    goToInventoryDetail = id => this.props.history.push(`/inventory/${id}`);

    pharmacyChange = e => this.setState({ pharmacyId: e.target.value })

    openModal = e => {
        e.preventDefault();
        M.Modal.getInstance(document.querySelector('#new-inventory-modal')).open();
        document.querySelector('#alias').focus();
    }

    cancel = e => {
        e.preventDefault();
        M.Modal.getInstance(document.querySelector('#new-inventory-modal')).close();
        this.setState({ alias: '' })
    }


    archive = (e, row) => {
        e.preventDefault();

        Axios.post('/api/v1/customerreports/inventory/archive', {
            ...this.authData,
            orderHeaderId: row.id
        })
            .then(result => {
                M.toast({ html: `Inventory "${row.alias}" archived` });
                this.getInventories();
            })
            .catch(err => {
                if (err.response.status === 401) {
                    sessionStorage.clear();
                    sessionStorage.setItem('logged_out', true);
                    this.props.history.push('/');
                }
                else {
                    M.toast({ html: 'An unknown error occurred please try again.' })
                }
            })
    }

    activate = (e, row) => {
        e.preventDefault();

        Axios.post('/api/v1/customerreports/inventory/activate', {
            ...this.authData,
            orderHeaderId: row.id
        })
            .then(result => {
                M.toast({ html: `Inventory "${row.alias}" re-activated` });
                this.getInventories();
            })
            .catch(err => {
                if (err.response.status === 401) {
                    sessionStorage.clear();
                    sessionStorage.setItem('loggged_out', true);
                    this.props.history.push('/');
                }
                else {
                    M.toast({ html: 'An unknown error ocurred please try again' })
                }
            })
    }

    render = () => (
        <div className="offset-for-sidenav">
            <div className="row">
                <h3 className="center">Inventory</h3>
            </div>
            <div className="row">
                <div className="input-field col s6 m2">
                    <select name="showArchived" onChange={this.onChange}>
                        <option value={""}>Show Active</option>
                        <option value={"true"}>Show Archived</option>
                    </select>
                </div>
                <div className="input-field col s6 m2">
                    <a href="/" onClick={this.openModal} className="btn-small waves-effect blue white-text">Start New Inventory</a>
                </div>
            </div>
            {this.state.gettingInventories ?
                <div className="progress">
                    <div className="indeterminate" />
                </div>
                :
                <div>
                    {this.state.showArchived ? (
                        <table>
                            <thead>
                                <tr>
                                    <th>Date Created</th>
                                    <th>Nick Name</th>
                                    <th>Actions</th>
                                </tr>
                            </thead>
                            <tbody>
                                {this.state.archived && this.state.archived.map(row => (
                                    <tr key={`archived-${row.id}`}>
                                        <td><Link to={`/inventory/${row.id}`}>{row.dateCreated}</Link></td>
                                        <td>{row.alias}</td>
                                        <td><a href="/" onClick={e => this.activate(e, row)}>Re-Activate Inventory</a></td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                    )
                        : (
                            <table>
                                <thead>
                                    <tr>
                                        <th>Date Created</th>
                                        <th>Nick Name</th>
                                        <th>Actions</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {this.state.active && this.state.active.map(row => (
                                        <tr key={`tr-${row.id}`}>
                                            <td style={{ padding: '6px' }}><Link to={`/inventory/${row.id}`}>{row.dateCreated}</Link></td>
                                            <td style={{ padding: '6px' }}>{row.alias}</td>
                                            <td><a href="/" onClick={e => this.archive(e, row)}>Archive Inventory</a></td>
                                        </tr>
                                    ))}
                                </tbody>
                            </table >
                        )}
                </div >
            }
            <div id="new-inventory-modal" className="modal">
                <div className="modal-content">
                    <div className="row">
                        <h4>Create New Inventory</h4>
                    </div>
                    <div className="row">
                        <div className="input-field col s12 m4">
                            <input id="alias" name="alias" type="text" onChange={this.onChange} value={this.state.alias} />
                            <label htmlFor="alias">Nick Name (Required)</label>
                        </div>
                    </div>
                    <div className="row">
                        <div className="input-field col s12 m4 l2 right">
                            <a href="/" className="btn-small red white-text waves-effect waves-light col s12" onClick={this.cancel}>Cancel</a>
                        </div>
                        <div className="input-field col 12 m4 l2 right">
                            <a href="/" className="btn-small blue white-text waves-effect waves-light col s12" onClick={this.createInventory}>Start New Inventory</a>
                        </div>
                    </div>
                </div>
            </div>
        </div >
    )
}

export default withRouter(InventoryOverview);