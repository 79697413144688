import Axios from 'axios';
import React from 'react';
import M from 'materialize-css';

class UserInfo extends React.Component {
    constructor(props) {
        super(props);
        this.state = this.initState()
        this.authData = JSON.parse(window.sessionStorage.getItem('auth_data'));
    }

    initState = () => ({
        loading: true,
        updating: false,
        user: null,
        msg: '',
        values: this.initValues()
    })

    initValues = () => ({
        firstName: '',
        lastName: '',
        title: '',
        emailAddress: '',
        phone: ''
    })

    componentDidMount = () => {
        this.getUser();
    }

    getUser = () => {
        this.setState({ loading: true }, () => {
            Axios.post('/api/v1/customerreports/userinfo', this.authData)
                .then(result => {
                    Object.keys(result.data).forEach(key => result.data[key] = result.data[key] ? result.data[key] : '')
                    this.setState({ user: result.data, values: result.data }, () => {
                        M.updateTextFields();
                    })
                })
                .catch(err => {
                    if (err.response.status === 401) {
                        sessionStorage.clear();
                        sessionStorage.setItem('logged_out', true);
                        this.props.history.push('/');
                    }
                    else {
                        M.toast({ html: 'An unknown error occurred please try again.' })
                    }
                })
                .finally(() => this.setState({ loading: false }))
        })
    }

    onChange = e => {
        e.persist();
        this.setState(p => ({ values: { ...p.values, [e.target.id]: e.target.value } }))
    }

    clear = e => {
        e.preventDefault();

        this.setState(p => ({ values: p.user }), () => {
            M.updateTextFields();
        })
    }

    update = e => {
        e.preventDefault();

        const { firstName, lastName, emailAddress } = this.state.values;
        let msg = '';

        if (!firstName)
            msg = 'First Name is Required';

        if (!lastName)
            msg = 'Last Name is Required';

        if (!emailAddress)
            msg = 'Email Address is Requires';

        if (msg)
            return this.setState({ msg })

        this.setState({ updating: true }, () => {
            Axios.post('/api/v1/customerreports/updateuserinfo', {
                ...this.authData,
                ...this.state.values
            })
                .then(result => {
                    M.toast({ html: 'User Info Successfully Updated' });
                    this.setState(p => ({ user: p.values }))
                })
                .catch(err => {
                    if (err.response.status === 401) {
                        sessionStorage.clear();
                        sessionStorage.setItem('logged_out', true);
                        this.props.history.push('/');
                    }
                    else {
                        M.toast({ html: 'An unknown error occurred please try again.' })
                    }
                })
                .finally(() => this.setState({ updating: false }))
        })
    }

    render = () => (
        <div className="col 12">
            <div className="card">
                <div className="card-content">
                    <form>
                        <div className="row">
                            <div className="input-field col s12 m8 l4">
                                <input id="firstName" type="text" onChange={this.onChange} value={this.state.values.firstName} />
                                <label htmlFor="firstName">First Name</label>
                            </div>
                            <div className="input-field col s12 m8 l4">
                                <input id="lastName" type="text" onChange={this.onChange} value={this.state.values.lastName} />
                                <label htmlFor="lastName">Last Name</label>
                            </div>
                        </div>
                        <div className="row">
                            <div className="input-field col s12 m6 l2">
                                <input id="title" type="text" onChange={this.onChange} value={this.state.values.title} />
                                <label htmlFor="title">Title/Position</label>
                            </div>
                        </div>
                        <div className="row">
                            <div className="input-field col s12 m8 l6">
                                <input id="emailAddress" type="text" onChange={this.onChange} value={this.state.values.emailAddress} />
                                <label htmlFor="email">Preferred Contact Email</label>
                            </div>
                            <div className="input-field col s12 m4 l2">
                                <input id="phone" type="text" onChange={this.onChange} value={this.state.values.phone} />
                                <label htmlFor="phone">Phone</label>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col s12 m4 l3">
                                {this.state.msg ?
                                    <p className="red-text center col s12">{this.state.msg}</p>
                                    :
                                    <a href="/" className="btn-small col s12 blue white-text waves-effect waves-light" onClick={this.update}>Update</a>
                                }
                            </div>
                            <div className="col s12 m4 l3">
                                <a href="/" className="btn-small col s12 white black-text waves-effect waves-dark" onClick={this.clear}>Clear Changes</a>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    )
}

export default UserInfo;