import React from 'react';
import { Route, Switch } from 'react-router-dom';
import ControlsOverview from './controlsOverview';
import ControlsDetail from './controlsDetail';


const ControlsRouter = props => {
    sessionStorage.setItem('page', 'Orders with Controls')
    return (
        <Switch>
            <Route exact path="/controls" component={ControlsOverview} />
            <Route exact path="/controls/:orderNumber" component={ControlsDetail} />
        </Switch>
    )
}

export default ControlsRouter;