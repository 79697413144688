import React from 'react';
import M from 'materialize-css';
import Axios from 'axios';
import { Link } from 'react-router-dom';
import moment from 'moment';

class MedSearch extends React.Component {
    constructor(props) {
        super(props);
        this.state = this.initState();
        this.authData = JSON.parse(sessionStorage.getItem('auth_data'));
        this.pharmacies = JSON.parse(sessionStorage.getItem('pharmacies'));
    }

    initState = () => ({
        orders: null,
        headers: [
            'NDC',
            'Med Name',
            'Manufacturer',
            'Exp Date',
            'Lot Number',
            'Pkg Size',
            'Full Pkgs',
            'Partial Count',
            'DEA Class',
            'Reason Code',
            'Order Number'
        ],
        reasonCodes: [
            { value: 'ANY', reason: 'Any' },
            { value: '1', reason: 'Manufacturer does not offer return' },
            { value: '2', reason: 'No open/partially used containers' },
            { value: '3', reason: 'Too far expired' },
            { value: '4', reason: 'Labeled product - seeeking exception' },
            { value: '5', reason: 'In-dated' },
            { value: '6', reason: 'Lot Number or expiration date missing or damaged' },
            { value: '7', reason: 'Not original container' },
            { value: '8', reason: 'Doesn\'t meet manufacturer minimum requirements' },
            { value: '9', reason: 'Product exclusion/denied return' },
            { value: '20', reason: 'Seeking exception' }
        ],
        pageSizes: [100, 150, 200, 250],
        deaClasses: ['Any', 'R', '2', '3', '4', '5'],
        header: 'NDC',
        isAsc: true,
        searchNdc: '',
        searchMedName: '',
        searchManufacturer: '',
        searchLotNumber: '',
        searchDeaClass: 'ANY',
        searchNrrc: 'ANY',
        pageSize: 100,
        offset: 0,
        resultCount: 0,
        searchInProgress: false,
        gettingPrintables: false,
        pdfDownloadURL: null,
        csvDownloadURL: null,
        pharmacyId: '0'
    })

    componentDidMount = () => {
        M.FormSelect.init(document.querySelectorAll('select'));
    }

    componentWillUnmount = () => {
        document.querySelectorAll('select').forEach(el => {
            const s = M.FormSelect.getInstance(el);
            if (s)
                s.destroy();
        });
    }

    onChange = e => this.setState({ [e.target.name]: e.target.value });

    search = e => {
        if (e) { e.preventDefault() }
        const searchParams = {
            ...this.authData,
            ndc: this.state.searchNdc,
            medName: this.state.searchMedName,
            manufacturerName: this.state.searchManufacturer,
            lotNumber: this.state.searchLotNumber,
            deaClass: parseInt(this.state.searchDeaClass) ? this.state.searchDeaClass : 'ANY',
            nonReturnableReasonCodeId: this.state.searchNrrc,
            nonReturnableReasonCode: this.state.reasonCodes.filter(rc => rc.value === this.state.searchNrrc)[0].reason,
            header: this.state.header,
            isAsc: this.state.isAsc,
            offset: (parseInt(this.state.offset) * parseInt(this.state.pageSize)),
            pageSize: parseInt(this.state.pageSize),
            pharmacyId: this.state.pharmacyId
        }
        this.setState({ previousSearch: searchParams, searchInProgress: true }, () => {
            Axios.post('/api/v1/customerreports/custom/by/med', {
                ...searchParams,
                deaClass: searchParams.deaClass === '1' ? 'R' : searchParams.deaClass
            })
                .then(result => this.paginate(result.data))
                .catch(err => {
                    if (err.response.status === 401) {
                        sessionStorage.clear();
                        sessionStorage.setItem('logged_out', true);
                        this.props.history.push('/');
                    }
                    else {
                        M.toast({ html: 'An unknown error occurred please try again.' })
                    }
                })
                .finally(() => this.setState({ searchInProgress: false }))
        })
    }

    setHeader = header => {
        if (!this.state.orders)
            return;

        if (header === this.state.header)
            this.setState(p => ({ isAsc: !p.isAsc, offset: 0 }), this.search);
        else
            this.setState({ header, isAsc: true, offset: 0 }, this.search)
    }

    paginate = obj => {
        let numPages = Math.ceil(obj.totalCount / this.state.pageSize);
        const pageCount = [];
        for (let i = 1; i <= numPages; i++) {
            pageCount.push(i);
        }

        this.setState({ pageCount, orders: obj.rows, resultCount: parseInt(obj.totalCount), pdfDownloadURL: null })
    }

    pharmacyChange = e => this.setState({ pharmacyId: e.target.value })

    pageLeft = e => { e.preventDefault(); this.setState(p => ({ offset: --p.offset }), this.search) }
    pageRight = e => { e.preventDefault(); this.setState(p => ({ offset: ++p.offset }), this.search) }
    pageSelect = (index) => this.setState({ offset: index }, this.search);

    getPrintables = e => {
        e.preventDefault();
        this.setState({ gettingPrintables: true }, () => {
            const pdfPromise = Axios.post('/api/v1/customerreports/custom/by/med/pdf', this.state.previousSearch, { responseType: 'blob' })
                .then(result => {
                    const url = window.URL.createObjectURL(new Blob([result.data]));
                    this.setState({ pdfDownloadURL: url })
                })
                .catch(err => {
                    if (err.response.status === 401) {
                        sessionStorage.clear();
                        sessionStorage.setItem('logged_out', true);
                        this.props.history.push('/');
                    }
                    else {
                        M.toast({ html: 'An unknown error occurred please try again.' })
                    }
                })

            const csvPromise = Axios.post('/api/v1/customerreports/custom/by/med/csv', this.state.previousSearch, { responseType: 'blob' })
                .then(result => {
                    const url = window.URL.createObjectURL(new Blob([result.data], { type: 'text/csv;charset=utf-8' }));
                    this.setState({ csvDownloadURL: url })
                })
                .catch(err => {
                    if (err.response.status === 401) {
                        sessionStorage.clear();
                        sessionStorage.setItem('logged_out', true);
                        this.props.history.push('/');
                    }
                    else {
                        M.toast({ html: 'An unknown error occurred please try again.' })
                    }
                })

            Promise.all([pdfPromise, csvPromise]).then(() => this.setState({ gettingPrintables: false }))
        })
    }

    render = () => (
        <div className="container-fluid">
            <div className="row">
                <div className="input-field col s12 m3">
                    <input id="_searchNdc" name="searchNdc" type="text" onChange={this.onChange} value={this.state.searchNdc} />
                    <label htmlFor="_searchNdc">NDC</label>
                </div>
                <div className="input-field col s12 m3">
                    <input id="_searchMedName" name="searchMedName" type="text" onChange={this.onChange} value={this.state.searchMedName} />
                    <label htmlFor="_searchMedName">Med Name</label>
                </div>
                <div className="input-field col s12 m3">
                    <input id="_searchManufacturer" name="searchManufacturer" type="text" onChange={this.onChange} value={this.state.searchManufacturer} />
                    <label htmlFor="_searchManufacturer">Manufacturer</label>
                </div>
                <div className="input-field col s12 m3">
                    <input id="_searchLotNumber" name="searchLotNumber" type="text" onChange={this.onChange} value={this.state.searchLotNumber} />
                    <label htmlFor="_searchLotNumber">Lot Number</label>
                </div>
            </div>
            <div className="row">
                <div className="input-field col s12 m2">
                    <select name="searchDeaClass" onChange={this.onChange} value={this.state.searchDeaClass}>
                        {this.state.deaClasses.map((dc, index) => <option key={`dc=${index}`} value={index}>{dc}</option>)}
                    </select>
                    <label>Dea Class</label>
                </div>
                <div className="input-field col s12 m4">
                    <select name="searchNrrc" onChange={this.onChange} value={this.state.searchNrrc}>
                        {this.state.reasonCodes.map((rc, index) => <option key={`rc-${index}`} value={rc.value}>{rc.reason}</option>)}
                    </select>
                    <label>Reason</label>
                </div>
                <div className="input-field col s12 m2">
                    <select name="pageSize" onChange={this.onChange} value={this.state.pageSize}>
                        {this.state.pageSizes.map(ps => <option key={`ps-${ps}`} value={ps}>{ps}</option>)}
                    </select>
                    <label>Results Per Page</label>
                </div>
                {this.pharmacies.length > 1 &&
                    <div className="input-field col s12 m4">
                        <select onChange={this.pharmacyChange}>
                            <option value={0}>All Pharmacies</option>
                            {this.pharmacies.map((p, index) => <option key={`opt-${index}`} value={p.id}>{p.name}</option>)}
                        </select>
                        <label>Pharmacy</label>
                    </div>
                }
            </div>
            <div className="row">
                {(this.state.searchInProgress || this.state.gettingPrintables) ?
                    <div className="col s12">
                        <div className="preloader-wrapper small active">
                            <div className="spinner-layer spinner-blue-only">
                                <div className="circle-clipper left">
                                    <div className="circle"></div>
                                </div><div className="gap-patch">
                                    <div className="circle"></div>
                                </div><div className="circle-clipper right">
                                    <div className="circle"></div>
                                </div>
                            </div>
                        </div>
                    </div>
                    :
                    <div className="row">
                        <div className=" input-field col s12 m2 l1 ">
                            <a className="waves-effect btn-small blue white-text" href="/" onClick={this.search}>Search</a>
                        </div>
                        {this.state.pdfDownloadURL ?
                            <div className="col s12 m4">
                                <a href={this.state.pdfDownloadURL} style={{ marginLeft: '12px' }} download={`${moment().format('MM/DD/YY')}_custom_report.pdf`}>DOWNLOAD PDF</a>
                                <a href={this.state.csvDownloadURL} style={{ marginLeft: '12px' }} download={`${moment().format('MM/DD/YY')}_custom_report.csv`}>DOWNLOAD CSV</a>
                            </div>
                            :
                            <div className="input-field col s12 m2 l1">
                                <a className={`waves-effect btn-small light-blue white-text ${(this.state.orders && !this.state.pdfDownloadURL) ? '' : 'disabled'}`} style={{ marginLeft: '12px' }} href="/" onClick={this.getPrintables}>Print</a>
                            </div>
                        }
                    </div>
                }
            </div>
            <div className="row">
                <h6 style={{ display: 'inline-block' }} className="grey-text">{this.state.orders ? `${this.state.resultCount} Results` : ''}</h6>
                {(this.state.orders && this.state.pageCount.length > 1) ? (
                    <div className="div">
                        <ul className="pagination center">
                            <li className={this.state.offset === this.state.pageCount[0] - 1 ? 'disabled' : ''}><a href="/" onClick={this.pageLeft}><i className="material-icons">chevron_left</i></a></li>
                            {this.state.pageCount.map((page, index) => (
                                <li key={`li-${index}`} className={this.state.offset === this.state.pageCount[index] - 1 ? 'active blue' : 'waves-effect'}><a href="/" onClick={e => { e.preventDefault(); this.pageSelect(index) }}>{page}</a></li>
                            ))}
                            <li className={this.state.offset === this.state.pageCount[this.state.pageCount.length - 1] - 1 ? 'disabled' : ''}><a href="/" onClick={this.pageRight}><i className="material-icons">chevron_right</i></a></li>
                        </ul>
                    </div>
                ) : null
                }

            </div>
            <table>
                <thead>
                    <tr>
                        {this.state.headers.map(header => (
                            <th
                                key={`th-${header}`}
                                onClick={() => this.setHeader(header)}
                                style={{ cursor: 'pointer' }}
                            >
                                {header}
                                {this.state.header === header ? (
                                    <i className="material-icons">{this.state.isAsc ? 'arrow_drop_up' : 'arrow_drop_down'}</i>
                                ) : null}
                            </th>
                        ))}
                    </tr>
                </thead>
                <tbody>
                    {this.state.orders && this.state.orders.map(o => (
                        <tr key={`tr-${o.id}`}>
                            <td style={{ padding: '6px' }}>{o.ndc}</td>
                            <td style={{ padding: '6px' }}>{o.medName}</td>
                            <td style={{ padding: '6px' }}>{o.manufacturerName}</td>
                            <td style={{ padding: '6px' }}>{o.expDate}</td>
                            <td style={{ padding: '6px' }}>{o.lotNumber}</td>
                            <td style={{ padding: '6px' }}>{o.packSize}</td>
                            <td style={{ padding: '6px' }}>{o.fullPackage}</td>
                            <td style={{ padding: '6px' }}>{o.partialPackage}</td>
                            <td style={{ padding: '6px' }}>{o.deaClass}</td>
                            <td style={{ padding: '6px' }}>{o.nonReturnableReasonCodeId}</td>
                            <td style={{ padding: '6px' }}><Link to={`/${isNaN(parseInt(o.deaClass)) ? 'orderdetail' : 'controls'}/${o.orderNumber}`}>{o.orderNumber}</Link></td>
                        </tr>
                    ))}
                </tbody>
            </table>
        </div >
    )
}

export default MedSearch;