import Axios from 'axios';
import React from 'react';
import M from 'materialize-css';

class OrderDetail extends React.Component {
    constructor(props) {
        super(props);
        this.state = this.initState();
        this.authData = JSON.parse(window.sessionStorage.getItem('auth_data'))
    }

    initState = () => ({
        returnables: null,
        nonReturnables: null,
        csvDownloadURL: '',
        loading: false,
        gettingDetails: false,
        isVa: false,
        isDestructionOnly: false
    })

    componentDidMount = async () => {
        const pathArr = window.location.pathname.split('/')
        const orderNumber = pathArr[pathArr.length - 1];
        const isDestructionOnly = await this.getIsDestruction(orderNumber);
        if (isDestructionOnly) {
            return this.setState({ isDestructionOnly })
        }

        const isVa = await this.getIsVa(orderNumber);

        this.setState({ orderNumber, isVa });
        this.getDetails(orderNumber);
        this.getPrintables(orderNumber, isVa);
    }

    getIsDestruction = orderNumber => new Promise((resolve, reject) => {
        Axios.post('/api/v1/customerreports/isdestructiononlybyordernumber', { ...this.authData, orderNumber })
            .then(result => this.setState({ isDestructionOnly: result.data.isDestructionOnly }, () => {
                resolve(result.data.isDestructionOnly);
            }))
            .catch(err => {
                if (err.response.status === 401) {
                    sessionStorage.clear();
                    sessionStorage.setItem('logged_out', true);
                    this.props.history.push('/');
                }
                else {
                    M.toast({ html: 'An unknown error occurred please try again.' })
                }
                reject(false);
            })
    })

    getIsVa = (orderNumber) => new Promise((resolve, reject) => {
        Axios.post('/api/v1/customerreports/isvabyordernumber', { ...this.authData, orderNumber })
            .then(result => this.setState({ isVa: result.data.isVa }, () => {
                resolve(result.data.isVa)
            }))
            .catch(err => {
                if (err.response.status === 401) {
                    sessionStorage.clear();
                    sessionStorage.setItem('logged_out', true);
                    this.props.history.push('/');
                }
                else {
                    M.toast({ html: 'An unknown error occurred please try again.' })
                }
                reject(false);
            })
    })

    getPrintables = (orderNumber, isVa) => {
        this.setState({ loading: true })


        const pdfPromise = Axios.post('/api/v1/customerreports/detail/pdf', { ...this.authData, orderNumber, isVa }, { responseType: 'blob' })
            .then(result => {
                const url = window.URL.createObjectURL(new Blob([result.data]));
                this.setState({ pdfDownloadURL: url })
            })
            .catch(err => {
                if (err.response.status === 401) {
                    sessionStorage.clear();
                    sessionStorage.setItem('logged_out', true);
                    this.props.history.push('/');
                }
                else {
                    M.toast({ html: 'An unknown error occurred please try again.' })
                }
            })

        const csvPromise = Axios.post('/api/v1/customerreports/detail/csv', { ...this.authData, orderNumber, isVa }, { responseType: 'blob' })
            .then(result => {
                const url = window.URL.createObjectURL(new Blob([result.data], { type: 'text/csv;charset=utf-8' }));
                this.setState({ csvDownloadURL: url })
            })
            .catch(err => {
                if (err.response.status === 401) {
                    sessionStorage.clear();
                    sessionStorage.setItem('logged_out', true);
                    this.props.history.push('/');
                }
                else {
                    M.toast({ html: 'An unknown error occurred please try again.' })
                }
            })

        Promise.all([pdfPromise, csvPromise]).then(() => this.setState({ loading: false }))

    }

    getDetails = (orderNumber) => {
        this.setState({ gettingDetails: true }, () => {
            Axios.post('/api/v1/customerreports/detail', { ...this.authData, orderNumber })
                .then(result => this.setState(p => ({ ...p, ...result.data })))
                .catch(err => {
                    if (err.response.status === 401) {
                        sessionStorage.clear();
                        sessionStorage.setItem('logged_out', true);
                        this.props.history.push('/');
                    }
                    else {
                        M.toast({ html: 'An unknown error occurred please try again.' })
                    }
                })
                .finally(() => this.setState({ gettingDetails: false }))
        })
    }


    render = () => (
        <div className="offset-for-sidenav">
            <div className="row">
                <h4 className="center">Alphabetical Inventory Report</h4>
                {this.state.isDestructionOnly ? <h5 className="center orange" style={{fontWeight: '600'}}>THIS ORDER IS DESTRUCTION ONLY</h5> : null}
            </div>
            {!this.state.loading ? (
                <div className="row">
                    {!this.state.isDestructionOnly &&
                        <div className="col s12 m4" style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-around' }}>
                            <a href={this.state.pdfDownloadURL} download={`${this.state.orderNumber}.pdf`}>DOWNLOAD PDF</a>
                            <a href={this.state.csvDownloadURL} download={`${this.state.orderNumber}.csv`}>DOWNLOAD CSV</a>
                        </div>
                    }
                </div>
            ) : (
                <div className="row">
                    <div className="preloader-wrapper small active">
                        <div className="spinner-layer spinner-blue-only">
                            <div className="circle-clipper left">
                                <div className="circle"></div>
                            </div><div className="gap-patch">
                                <div className="circle"></div>
                            </div><div className="circle-clipper right">
                                <div className="circle"></div>
                            </div>
                        </div>
                    </div>
                </div>)}
            {this.state.gettingDetails ?
                <div className="progress">
                    <div className="indeterminate" />
                </div>
                :
                <div style={{ overflowX: 'auto' }}>
                    <table className="highlight">
                        <thead>
                            <tr>
                                <th>NDC</th>
                                <th>DEA Class</th>
                                <th>Med Name</th>
                                <th>Manufacturer</th>
                                <th>Exp Date</th>
                                <th>Lot Number</th>
                                {this.state.isVa ? <th>Destruction #</th> : null}
                                <th>Pkg Size</th>
                                <th>Full Pkgs</th>
                                <th>Partial Count</th>
                                <th style={{ textAlign: 'right' }}>Published Value</th>
                                <th style={{ textAlign: 'right' }}>Estimated Return</th>
                            </tr>
                        </thead>
                        <tbody>
                            {this.state.returnables && this.state.returnables.map(od => (
                                <tr key={`returnable${od.id}`}>
                                    <td style={{ padding: '6px' }}>{od.ndc}</td>
                                    <td style={{ padding: '6px' }}>{od.deaClass}</td>
                                    <td style={{ padding: '6px' }}>{od.medName}</td>
                                    <td style={{ padding: '6px' }}>{od.manufacturerName}</td>
                                    <td style={{ padding: '6px' }}>{od.expiration}</td>
                                    {this.state.isVa ? <td style={{ padding: '6px' }}>{od.lotNumber}</td> : null}
                                    <td style={{ padding: '6px' }}>{od.vaDestructionNumber}</td>
                                    <td style={{ padding: '6px' }}>{od.packSize}</td>
                                    <td style={{ padding: '6px' }}>{od.fullPackage}</td>
                                    <td style={{ padding: '6px', borderRight: '1px dotted black' }}>{od.partialPackage}</td>
                                    <td style={{ padding: '6px', textAlign: 'right' }}>{parseFloat(od.publishedValue).toLocaleString('en-US', { minimumFractionDigits: 2 })}</td>
                                    <td style={{ padding: '6px', textAlign: 'right' }}>{parseFloat(od.estimatedValue).toLocaleString('en-US', { minimumFractionDigits: 2 })}</td>
                                </tr>
                            ))}
                            {this.state.returnables && (
                                <tr style={{ border: 'none', borderTop: 'black solid 2px' }}>
                                    <td style={{ padding: '6px' }}><b>Total Returnables:</b></td>
                                    <td style={{ padding: '6px' }}></td>
                                    <td style={{ padding: '6px' }}></td>
                                    <td style={{ padding: '6px' }}></td>
                                    <td style={{ padding: '6px' }}></td>
                                    <td style={{ padding: '6px' }}></td>
                                    <td style={{ padding: '6px' }}></td>
                                    <td style={{ padding: '6px' }}></td>
                                    {this.state.isVa ? <td style={{ padding: '6px' }}></td> : null}
                                    <td style={{ padding: '6px', borderRight: '1px dotted black' }}></td>
                                    <td style={{ padding: '6px', textAlign: 'right' }}><b>${parseFloat(this.state.returnablePublishedTotal).toLocaleString('en-US', { minimumFractionDigits: 2 })}</b></td>
                                    <td style={{ padding: '6px', textAlign: 'right' }}><b>${parseFloat(this.state.returnableEstimatedTotal).toLocaleString('en-US', { minimumFractionDigits: 2 })}</b></td>
                                </tr>
                            )}
                        </tbody>
                    </table>
                    <div className="row">
                        <h4 className="center red-text">Non-Returnable</h4>
                    </div>
                    <table className="highlight" style={{ marginBottom: '80px' }}>
                        <thead>
                            <tr>
                                <th>NDC</th>
                                <th>DEA Class</th>
                                <th>Med Name</th>
                                <th>Manufacturer</th>
                                <th>Exp Date</th>
                                <th>Lot Number</th>
                                {this.state.isVa ? <th>Destruction #</th> : null}
                                <th>Pkg Size</th>
                                <th>Full Pkgs</th>
                                <th>Partial Count</th>
                                <th>Reason Code</th>
                                <th style={{ textAlign: 'right' }}>Published Value</th>
                            </tr>
                        </thead>
                        <tbody>
                            {this.state.nonReturnables && this.state.nonReturnables.map(od => (
                                <tr key={`non-returnable-${od.id}`}>
                                    <td style={{ padding: '6px' }}>{od.ndc}</td>
                                    <td style={{ padding: '6px' }}>{od.deaClass}</td>
                                    <td style={{ padding: '6px' }}>{od.medName}</td>
                                    <td style={{ padding: '6px' }}>{od.manufacturerName}</td>
                                    <td style={{ padding: '6px' }}>{od.expiration}</td>
                                    <td style={{ padding: '6px' }}>{od.lotNumber}</td>
                                    {this.state.isVa ? <td style={{ padding: '6px' }}>{od.vaDestructionNumber}</td> : null}
                                    <td style={{ padding: '6px' }}>{od.packSize}</td>
                                    <td style={{ padding: '6px' }}>{od.fullPackage}</td>
                                    <td style={{ padding: '6px' }}>{od.partialPackage}</td>
                                    <td style={{ padding: '6px', borderRight: '1px dotted black' }}>{od.nonReturnableReasonCodeId}</td>
                                    <td style={{ padding: '6px', textAlign: 'right' }}>{parseFloat(od.publishedValue || 0).toLocaleString('en-US', { minimumFractionDigits: 2 })}</td>
                                </tr>
                            ))}
                            {this.state.returnables && (
                                <tr style={{ border: 'none', borderTop: 'black solid 2px' }}>
                                    <td style={{ padding: '6px' }}><b>Total Non-Returnables:</b></td>
                                    <td style={{ padding: '6px' }}></td>
                                    <td style={{ padding: '6px' }}></td>
                                    <td style={{ padding: '6px' }}></td>
                                    <td style={{ padding: '6px' }}></td>
                                    <td style={{ padding: '6px' }}></td>
                                    <td style={{ padding: '6px' }}></td>
                                    <td style={{ padding: '6px' }}></td>
                                    <td style={{ padding: '6px' }}></td>
                                    {this.state.isVa ? <td style={{ padding: '6px' }}></td> : null}
                                    <td style={{ padding: '6px', borderRight: '1px dotted black' }}></td>
                                    <td style={{ padding: '6px', textAlign: 'right' }}><b>{parseFloat(this.state.nonReturnablePublishedTotal).toLocaleString('en-US', { minimumFractionDigits: 2 })}</b></td>
                                </tr>
                            )}
                        </tbody>
                    </table>
                    <div className="row">
                        <ul>
                            <li><b>Non Returnable Reason Codes</b></li>
                            <li>1. Manufacturer does not offer return</li>
                            <li>2. No open/partially used containers</li>
                            <li>3. Too far expired</li>
                            <li>4. Labeled product</li>
                            <li>5. In-dated</li>
                            <li>6. Lot # or expiration date missing or damaged</li>
                            <li>7. Not original container</li>
                            <li>8. Does not meet manufacturer minimum requirements</li>
                            <li>9. Product exclusion/denied return</li>
                            <li>99. Customer Declined Return Service</li>
                        </ul>
                    </div>
                </div>
            }
        </div>
    )
}

export default OrderDetail;