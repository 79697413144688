import Axios from 'axios';
import React from 'react';
import M from 'materialize-css';
import { withRouter } from 'react-router-dom';
import * as xlsx from 'xlsx';


class InventoryDetail extends React.Component {
    constructor(props) {
        super(props);
        this.state = this.initState();
        this.authData = JSON.parse(sessionStorage.getItem('auth_data'));
    }

    initState = () => ({
        orderNumber: '',
        rows: [],
        ndc: '',
        medName: '',
        count: '',
        multipleMeds: null,
        editRow: -1,
        sortParam: 'id',
        reverseSort: false,
    })

    componentDidMount = () => {
        const { id } = this.props.match.params;
        this.getDetails(id);
        M.Modal.init(document.querySelectorAll('.modal'), { dismissible: false })
    }

    onChange = e => this.setState({ [e.target.name]: e.target.value })

    searchNDC = e => {
        if (e.key === 'Tab')
            setTimeout(() => {
                this.setState({ searchInProgress: true }, () => {
                    Axios.post('/api/v1/customerreports/inventory/search/ndc', { ...this.authData, ndc: this.state.ndc })
                        .then(result => {
                            if (result.data.length > 1) {
                                this.setState({ multipleMeds: result.data }, () => {
                                    M.Modal.getInstance(document.querySelector('#multiple-med-modal')).open();
                                })
                            }
                            else if (result.data.length)
                                this.setState({ medName: result.data[0].medName })
                            else
                                this.setState({ medName: '' })
                        })
                        .catch(err => {
                            if (err.response.status === 401) {
                                sessionStorage.clear();
                                sessionStorage.setItem('logged_out', true);
                                this.props.history.push('/');
                            }
                            else {
                                M.toast({ html: 'An unknown error occurred please try again.' })
                            }
                        })
                        .finally(() => this.setState({ searchInProgress: false }, () => {
                            document.getElementsByName('medName')[0].focus();
                        }))
                })
            }, 10)
    }

    addMed = e => {
        e.preventDefault();
        Axios.post('/api/v1/customerreports/inventory/addmed', {
            ...this.authData,
            ndc: this.state.ndc,
            medName: this.state.medName,
            lazyCount: this.state.count,
            orderHeaderId: this.props.match.params.id
        })
            .then(result => {
                const rows = JSON.parse(JSON.stringify(this.state.rows));
                rows.push({
                    id: result.data.insertId,
                    ndc: this.state.ndc,
                    medName: this.state.medName,
                    lazyCount: this.state.count
                })
                this.setState({
                    rows,
                    ndc: '',
                    medName: '',
                    count: '',
                    pdfDownloadURL: '',
                    csvDownloadURL: ''
                }, () => document.getElementById('form-start').focus());
            })
            .catch(err => {
                if (err.response.status === 401) {
                    sessionStorage.clear();
                    sessionStorage.setItem('logged_out', true);
                    this.props.history.push('/');
                }
                else {
                    M.toast({ html: 'An unknown error occurred please try again.' })
                }
            })
            .finally(() => this.setState({ pdfDownloadURL: null, csvDownloadURL: null }))
    }

    getDetails = id => {
        this.setState({ gettingDetails: true }, () => {
            Axios.post('/api/v1/customerreports/inventory/detail/read', { ...this.authData, orderHeaderId: id })
                .then(result => this.setState({ rows: result.data }))
                .catch(err => {
                    if (err.response.status === 401) {
                        sessionStorage.clear();
                        sessionStorage.setItem('logged_out', true);
                        this.props.history.push('/');
                    }
                    else {
                        M.toast({ html: 'An unknown error occurred please try again.' })
                    }
                })
                .finally(() => this.setState({ gettingDetails: false }))
        })
    }

    confirmArchive = e => {
        e.preventDefault();
        const confirm = window.confirm('Are you sure you want to delete this inventory?');

        if (confirm) {
            Axios.post('/api/v1/customerreports/inventory/archive', {
                ...this.authData,
                orderHeaderId: this.props.match.params.id
            })
                .then(result => this.props.history.push('/inventory'))
                .catch(err => {
                    if (err.response.status === 401) {
                        sessionStorage.clear();
                        sessionStorage.setItem('logged_out', true);
                        this.props.history.push('/');
                    }
                    else {
                        M.toast({ html: 'An unknown error occurred please try again.' })
                    }
                })
        }
    }

    deleteRow = (row, index) => {
        Axios.post('/api/v1/customerreports/inventory/row/delete', {
            ...this.authData,
            id: row.id
        })
            .then(() => {
                const rows = JSON.parse(JSON.stringify(this.state.rows));
                rows.splice(index, 1);
                this.setState({ rows })
            })
            .catch(err => {
                if (err.response.status === 401) {
                    sessionStorage.clear();
                    sessionStorage.setItem('logged_out', true);
                    this.props.history.push('/');
                }
                else {
                    M.toast({ html: 'An unknown error occurred please try again.' })
                }
            })
    }

    chooseMed = (e, med) => {
        e.preventDefault();
        this.setState({ medName: med.medName, multipleMeds: null }, () => {
            M.updateTextFields();
            M.Modal.getInstance(document.querySelector('#multiple-med-modal')).close();
            document.querySelector('#_count').focus();
        })
    }

    chooseNeither = e => {
        e.preventDefault();
        this.setState({ multipleMeds: null, medName: '' }, () => {
            M.Modal.getInstance(document.querySelector('#multiple-med-modal')).close();
            document.querySelector('#_medName').focus();
        })
    }

    editRow = index => {
        const rows = JSON.parse(JSON.stringify(this.state.rows));
        rows[index].oldCount = rows[index].lazyCount;

        this.setState({ editRow: index, rows }, () => {
            document.querySelector('#_countEdit').addEventListener('keyup', this.editListener);
            document.querySelector('#_countEdit').select();
        })
    }

    editCount = e => {
        const rows = JSON.parse(JSON.stringify(this.state.rows));
        rows[this.state.editRow].lazyCount = e.target.value;
        this.setState({ rows })
    }

    editListener = e => {
        if (e.keyCode === 13) {
            const med = this.state.rows[this.state.editRow];

            Axios.post('/api/v1/customerreports/inventory/edit/count', {
                ...this.authData,
                id: med.id,
                lazyCount: med.lazyCount
            })
                .then(result => {
                    const rows = JSON.parse(JSON.stringify(this.state.rows));
                    delete rows[this.state.editRow].oldCount;
                    this.setState({ rows, editRow: -1 })
                })
                .catch(err => {
                    const rows = JSON.parse(JSON.stringify(this.state.rows));
                    rows[this.state.editRow].lazyCount = rows[this.state.editRow].oldCount;
                    delete rows[this.state.editRow].oldCount;

                    this.setState({ editRow: -1, rows }, () => {
                        M.toast({ html: 'An unknown error occurred please try again.' })
                    })
                })
        }
    }

    sort = type => {
        const { rows, sortParam, reverseSort } = this.state;
        const reverse = type === sortParam ? !reverseSort : false;

        if (type === 'ndc')
            rows.sort((a, b) => (reverse ? b : a).ndc.toString().localeCompare((reverse ? a : b).ndc.toString()))
        else if (type === 'name')
            rows.sort((a, b) => (reverse ? b : a).medName.localeCompare((reverse ? a : b).medName))
        else if (type === 'count')
            rows.sort((a, b) => parseInt((reverse ? b : a).lazyCount) - parseInt((reverse ? a : b).lazyCount))
        else
            rows.sort((a, b) => a.id - b.id)

        this.setState({ rows, sortParam: type, reverseSort: reverse })
    }

    download = (e, type) => {
        if (e) { e.preventDefault(); }

        Axios.post(`/api/v1/customerreports/inventory/${type}`, {
            ...this.authData,
            orderHeaderId: this.props.match.params.id,
        }, {responseType: type === 'pdf' ? 'blob' : 'json'})
            .then(result => {

                if (type === 'pdf') {
                    const blob = new Blob([result.data]);
                    const url = window.URL.createObjectURL(blob);
                    const link = document.createElement('a');
                    link.hidden = true;
                    link.href = url;
                    link.setAttribute('download', `${this.state.rows[0].alias}.pdf`);
                    document.body.appendChild(link);
                    link.click();
                    document.body.removeChild(link);
                }

                if (type === 'excel') {
                    const wb = xlsx.utils.book_new();
                    const worksheet = xlsx.utils.json_to_sheet(result.data);
                    xlsx.utils.book_append_sheet(wb, worksheet, 'inventory');
                    xlsx.writeFile(wb, `${this.state.rows[0].alias}.xlsx`);
                }
            })
            .catch(err => {
                console.log(err)
                return;

                if (err.response.status === 401) {
                    sessionStorage.clear();
                    sessionStorage.setItem('logged_out', true);
                    this.props.history.push('/');
                }
                else {
                    M.toast({ html: 'An unknown error occurred please try again.' })
                }
            })
    }

    render = () => (
        <div className="offset-for-sidenav">
            <div className="row">
                <div className="input-field col s12 m2">
                    <input id="form-start" type="text" name="ndc" value={this.state.ndc} onChange={this.onChange} onKeyDown={this.searchNDC} />
                    <label htmlFor="form-start">NDC</label>
                </div>
                {this.state.searchInProgress ?
                    <div className="input-field col s6">
                        <div className="progress blue">
                            <div className="indeterminate" />
                        </div>
                    </div>
                    :
                    <div className="input-field col s6">
                        <input id="_medName" type="text" name="medName" value={this.state.medName} onChange={this.onChange} />
                        <label htmlFor="_medName">Med Name</label>
                    </div>
                }
                <div className="input-field col s12 m2">
                    <input id="_count" type="number" name="count" value={this.state.count} onChange={this.onChange} />
                    <label htmlFor="_count">Count</label>
                </div>
                <div className="input-field col s12 m2">
                    <a href="/" className="btn-small waves-effect blue white-text" onClick={this.addMed}>Add Med</a>
                </div>
            </div>
            <div className="row">
                <table>
                    <thead>
                        <tr>
                            <th onClick={() => this.sort('ndc')} style={{ cursor: 'pointer' }}>NDC <i className="material-icons" style={{ position: 'relative', top: '6px', color: this.state.sortParam === 'ndc' ? '#000' : '#ffffffff' }}>{this.state.reverseSort ? 'arrow_drop_up' : 'arrow_drop_down'}</i></th>
                            <th onClick={() => this.sort('name')} style={{ cursor: 'pointer' }}>Med Name<i className="material-icons" style={{ position: 'relative', top: '6px', color: this.state.sortParam === 'name' ? '#000' : '#ffffffff' }}>{this.state.reverseSort ? 'arrow_drop_up' : 'arrow_drop_down'}</i></th>
                            <th onClick={() => this.sort('count')} style={{ cursor: 'pointer' }}>Count<i className="material-icons" style={{ position: 'relative', top: '6px', color: this.state.sortParam === 'count' ? '#000' : '#ffffffff' }}>{this.state.reverseSort ? 'arrow_drop_up' : 'arrow_drop_down'}</i></th>
                            <th></th>
                        </tr>
                    </thead>
                    {this.state.gettingDetails ?
                        <tbody>
                            <tr>
                                <td colSpan={3}>
                                    <div className="progress">
                                        <div className="indeterminate" />
                                    </div>
                                </td>
                            </tr>
                        </tbody>
                        :
                        <tbody>
                            {this.state.rows && this.state.rows.map((row, index) => (
                                <tr key={`tr-${row.id}`}>
                                    <td style={{ padding: '4px' }}>{row.ndc}</td>
                                    <td style={{ padding: '4px' }}>{row.medName}</td>
                                    {this.state.editRow === index ?
                                        <td><input id="_countEdit" className="browser-default" type="text" onChange={this.editCount} value={row.lazyCount} /></td>
                                        :
                                        <td style={{ padding: '4px' }}>{row.lazyCount}</td>
                                    }
                                    <td style={{ padding: '4px', display: 'flex', flexDirection: 'row', justifyContent: 'space-evenly' }}>
                                        <i style={{ cursor: 'pointer' }} className="material-icons" onClick={() => this.editRow(index)}>edit</i>
                                        <i style={{ cursor: 'pointer' }} className="material-icons" onClick={() => this.deleteRow(row, index)}>delete</i>
                                    </td>
                                </tr>
                            ))}
                        </tbody>
                    }
                </table>
            </div>
            <div className="row">
                <div className="row" style={{ marginTop: '18px' }}>
                    <div className="col s12 m8 l6" style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
                        <a className="btn-small cyan" href="/" onClick={e => this.download(e, 'pdf')}>Download PDF</a>
                        <a className="btn-small cyan" href="/" onClick={e => this.download(e, 'excel')}>Download Excel</a>
                    </div>
                </div>
            </div>
            <div className="row">
                <a href="/" className="left red white-text btn-small" onClick={this.confirmArchive}>Archive Inventory Report</a>
            </div>
            <div id="multiple-med-modal" className="modal">
                <div className="modal-content">
                    <div className="row">
                        <h5>We didn't find that med, could it be one of these?</h5>
                    </div>
                    <div className="row">
                        {this.state.multipleMeds && this.state.multipleMeds.map(med => (
                            <div className="row">
                                <a href="/" onClick={e => this.chooseMed(e, med)}>{med.medName}</a>
                            </div>
                        ))}
                        <div className="row">
                            <div className="input-field col s12 m4 offset-m8">
                                <a className="btn-small red white-text waves-effect waves-light col s12" href="/" onClick={this.chooseNeither}>Not Listed</a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default withRouter(InventoryDetail);